/* eslint-disable  */
import { Avatar, IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../Components/Loader";
import RenderLoanPage from "../RenderLoanPage";
import * as moment from "moment";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { notificationsActionCreators } from "../../services/Actions";
import { TiDelete } from "react-icons/ti";
import './LoansNotifications.css';

function LoansNotifications() {
  const dispatch = useDispatch();
  const { GetNotificationsByLoanOfficers, ResetResponse, DeleteNotification } =
    bindActionCreators(notificationsActionCreators, dispatch);

  const notificationsObj = useSelector((state) => state?.notification);
  const { isLoading, notifications } = notificationsObj;

  console.log('notificationsObj', notificationsObj)

  useEffect(() => {
    // GetConversations()
    GetNotificationsByLoanOfficers();
  }, []);
  return (
    <div className="LoansNotifications">
      <RenderLoanPage title={""}>
        {isLoading && <Loader />}
        <div className="SectionTwoNotificationSection">
          <div className="notifications">
            {notifications.length > 0 ? (
              <div className="notif_content">
                {notifications?.map((notification, index) => {
                  const name = notification?.title
                    .split(" ")[0]
                    .charAt(0)
                    .toUpperCase();
                  // const name = notification?.title.split(' ')[0].charAt(0) + ' ' + notification?.title.split(' ')[1].charAt(0)
                  return (
                    <div className="flex-items" key={notification?._id}>
                      <div className="flex">
                        {/* <Avatar className="icon" /> */}

                        <Avatar className="icon">{name}</Avatar>

                        <div>
                          <h5 className="notif_title  ">
                            {notification?.title}
                          </h5>
                          <div className="notif_msg text-gray-500">
                            {notification?.message}
                          </div>
                          <div className="time text-gray-300">
                            {moment(notification?.created_at).calendar()}
                          </div>
                        </div>
                      </div>
                      <div>
                        <IconButton
                          onClick={(e) => {
                            DeleteNotification(notification?._id);
                          }}
                        >
                          <TiDelete />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <ToastContainer autoClose={3000} />
      </RenderLoanPage>
    </div>
  );
}

export default LoansNotifications;
