/* eslint-disable  */
import React from 'react'
import RenderAdminPage from '../RenderAdminPage'
import { useHistory } from 'react-router-dom'
import "./LoanBookings.css";
import "./FundIncrement.css";
import * as FileServer from "file-saver";

function FundIncrementDetailed() {
    const history = useHistory()
    const fundIncrement = history.location.state.state
    console.log('history', fundIncrement)

    const handleImageDownload = (url, name) => {
        // alert("front image download");
        FileServer.saveAs(url, name);
    };

    function getFileExtension(url) {
        // Use the URL constructor to extract the pathname from the URL
        const pathname = new URL(url).pathname;
      
        // Use a regular expression to extract the file extension
        const extensionMatch = /\.[0-9a-z]+$/i.exec(pathname);
      
        // Check if a match was found and return the extension (including the dot)
        return extensionMatch ? extensionMatch[0] : null;
      }
       const url = fundIncrement?.files[1].url;
const fileExtension = getFileExtension(url);

console.log('fileExtension', fileExtension);

    return (
        <div className='LoanApplicationDetailed'>
            <RenderAdminPage>
                <div className="flex justify-between">
                    <div
                        className="relative inline-block px-3 my-2 mx-2 py-1 font-semibold text-white leading-tight cursor-pointer"
                        onClick={() => history.goBack()}
                    >
                        <span
                            aria-hidden
                            className="absolute inset-0 bg-gray-900 opacity-50 rounded-full"
                        ></span>
                        <span className="relative">Back</span>
                    </div>
                </div>

                <div
                    className="loanDetailed_profile_container"
                    style={{
                        background: `url(${fundIncrement?.passport_image
                            ? fundIncrement?.passport_image
                            : "/images/dev/del.png"
                            }) no-repeat center center/cover`,
                    }}
                >
                    <div className="overlay ">
                        {/* <h2>User Information</h2> */}
                        <div className="profile">
                            <div className="items">
                                <div className="image">
                                    <img
                                        src={
                                            fundIncrement?.files[2].url
                                                ? fundIncrement?.files[2].url
                                                : "/images/dev/del.png"
                                        }
                                        alt="product"
                                    />
                                </div>
                                <div className="profile-name">{fundIncrement?.name}</div>

                            </div>
                            <div
                                className="pb-3"
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sideTwo my-4">
                    {/* <h3>Guarantor 2</h3> */}

                    <div className="item grid  mb-3 md:grid-cols-2">
                        <div className="name">Full name</div>
                        <div className="value">
                            {fundIncrement?.name}
                        </div>
                    </div>
                    <div className="item grid  mb-3 md:grid-cols-2">
                        <div className="name">Staff ID</div>
                        <div className="value">
                            {fundIncrement?.staff_id}
                        </div>
                    </div>
                    <div className="item grid  mb-3 md:grid-cols-2">
                        <div className="name">Current Contribution</div>
                        <div className="value">{fundIncrement?.current_contribution}</div>
                    </div>
                    <div className="item grid  mb-3 md:grid-cols-2">
                        <div className="name">Requested Amount</div>
                        <div className="value">{fundIncrement?.requested_increment}</div>
                    </div>
                    <div className="item grid  mb-3 md:grid-cols-2">
                        <div className="name">Ghana Card Number</div>
                        <div className="value">
                            {fundIncrement?.IDCardNumber}
                        </div>
                    </div>
                    <div className="item grid  mb-3 md:grid-cols-2">
                        <div className="name">Facility</div>
                        <div className="value">
                            {fundIncrement?.facility}
                        </div>
                    </div>
                    <div className="item grid  mb-3 md:grid-cols-2">
                        <div className="name">Date of Birth</div>
                        <div className="value">
                            {fundIncrement?.dob}
                        </div>
                    </div>
                    <div className="item grid  mb-3 md:grid-cols-2">
                        <div className="name">Mandate Number</div>
                        <div className="value">
                            {fundIncrement?.mandate_number}
                        </div>
                    </div>
                    <div className="item grid  mb-3 md:grid-cols-2">
                        <div className="name">Next of Kin</div>
                        <div className="value">
                            {fundIncrement?.next_of_kin
                            }
                        </div>
                    </div>
                    <div className="item grid  mb-3 md:grid-cols-2">
                        <div className="name">Started Date</div>
                        <div className="value">
                            {fundIncrement?.fromDate
                            }
                        </div>
                    </div>
                    <div className="item grid  mb-3 md:grid-cols-2">
                        <div className="name">End Date</div>
                        <div className="value">
                            {fundIncrement?.toDate
                            }
                        </div>
                    </div>
                            <hr/>
                    <h1 className='my-2 '>Files Uploaded</h1>
                    <div
                        className="filesDownload"

                    >
                        {/* <a className='pdfCover' onClick={() => {
                      handleImageDownload(
                        fundIncrement?.files[0].url,
                        "document.pdf"
                      );
                    }}>Payslip </a> */}

                    {
                        getFileExtension(fundIncrement?.files[0].url) === '.pdf' && (
                            <div className="imgCover">
                            <img title='download' src={'/images/dev/pdf.png'} alt="pdf" onClick={() => handleImageDownload(
                                fundIncrement?.files[0].url,
                                "document.pdf"
                            )} />
                            <div>Payslip</div>
                        </div>
                        )
                    }
                    {
                        getFileExtension(fundIncrement?.files[1].url) === '.pdf' && (
                            <div className="imgCover">
                            <img title='download' src={'/images/dev/pdf.png'} alt="pdf" onClick={() => handleImageDownload(
                                fundIncrement?.files[0].url,
                                "document.pdf"
                            )} />
                            <div>Payslip</div>
                        </div>
                        )
                    }
                    {
                        getFileExtension(fundIncrement?.files[2].url) === '.pdf' && (
                            <div className="imgCover">
                            <img title='download' src={'/images/dev/pdf.png'} alt="pdf" onClick={() => handleImageDownload(
                                fundIncrement?.files[0].url,
                                "document.pdf"
                            )} />
                            <div>Payslip</div>
                        </div>
                        )
                    }
                    
                      
                        <div className="imgCover">
                            <img title='download' src={fundIncrement?.files[1].url} alt="id_back"  onClick={() => handleImageDownload(
                                fundIncrement?.files[1].url,
                                "image.png"
                            )}/>
                        </div>
                        <div className="imgCover">
                            <img title='download' src={fundIncrement?.files[2].url} alt="id_back"  onClick={() => handleImageDownload(
                                fundIncrement?.files[2].url,
                                "image.png"
                            )}/>
                        </div>



                    </div>


                </div>
            </RenderAdminPage>
        </div>
    )
}

export default FundIncrementDetailed