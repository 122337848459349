/* eslint-disable  */
import React from 'react'
import RenderHirePurchasePage from '../RenderHirePurchasePage'
import { GetUserProfile } from '../../services/Actions/authActions';
import { useEffect } from 'react';
import { dataActionCreators } from '../../services/Actions';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useState } from 'react';
import { RolesContants } from '../../contants';
import { GetMartReport } from '../../services/Actions/dataAction';

function HirePurchaseReport() {
  const dispatch = useDispatch()



  const {ResetDataResponse} = bindActionCreators(dataActionCreators, dispatch)

  const [formData, setFormData] = useState("");
  const data = useSelector((state) => state?.data);
  const user = useSelector((state) => state?.auth);
  const { isLoading, response } = data;



const handleChnage = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGenerateInitiated = () => {
    if (
      user?.role?.includes(RolesContants.ADMIN || RolesContants.MART_OFFICER_HEAD)
    ) {
     
      if (!formData.fromDate || !formData.toDate) {
        toast.error("Choose the range. This is required");
      } else {
        dispatch(GetMartReport("initiated", formData.fromDate, formData.toDate));
      }
    } else {
      toast.error(
        "Please are not authorize to generate report on booked loans"
      );
    }
  };
  const handleGenerateApproved = () => {
    if (
      user?.role?.includes(RolesContants.ADMIN || RolesContants.MART_OFFICER_HEAD)
    ) {
     
      if (!formData.fromDate || !formData.toDate) {
        toast.error("Choose the range. This is required");
      } else {
        dispatch(GetMartReport("approved", formData.fromDate, formData.toDate));
      }
    } else {
      toast.error(
        "Please are not authorize to generate report on booked loans"
      );
    }
  };
  const handleGenerateShipped = () => {
    if (
      user?.role?.includes(RolesContants.ADMIN || RolesContants.MART_OFFICER_HEAD)
    ) {
     
      if (!formData.fromDate || !formData.toDate) {
        toast.error("Choose the range. This is required");
      } else {
        dispatch(GetMartReport("shipped", formData.fromDate, formData.toDate));
      }
    } else {
      toast.error(
        "Please are not authorize to generate report on booked loans"
      );
    }
  };
  const handleGenerateRejected = () => {
    if (
      user?.role?.includes(RolesContants.ADMIN || RolesContants.MART_OFFICER_HEAD)
    ) {
     
      if (!formData.fromDate || !formData.toDate) {
        toast.error("Choose the range. This is required");
      } else {
        dispatch(GetMartReport("rejected", formData.fromDate, formData.toDate));
      }
    } else {
      toast.error(
        "Please are not authorize to generate report on booked loans"
      );
    }
  };
  const handleGenerateAll = () => {
    console.log('user?.role?',user?.role )
    console.log('user?.role?', user?.role?.includes('MART_OFFICER'))
    if (
      user?.role?.includes('MART_OFFICER')
    ) {
     
      if (!formData.fromDate || !formData.toDate) {
        toast.error("Choose the range. This is required");
      } else {
        dispatch(GetMartReport("all", formData.fromDate, formData.toDate));
      }
    } else {
      toast.error(
        "Please are not authorize to generate report on orders"
      );
    }
  };

  useEffect(() => {
    dispatch(GetUserProfile());
  }, []);

  return (
    <div className='HirePurchaseReport'>
      <RenderHirePurchasePage>
      {/* {isLoading && <Loader />} */}
          <h2 className="text-center font-bold text-[2rem]">Generate Report</h2>
      <div>
        <h3 className="text-1xl font-bold py-2 "> All Orders</h3>
        <div className="grid gap-6 mb-1 md:grid-cols-2">
          <div>
            <label
              htmlFor="fromDate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              From
            </label>
            <input
              type="date"
              id="fromDate"
              className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              // defaultValue={seletedUserData?.name}
              name="fromDate"
              onChange={handleChnage}
            />
          </div>
          <div>
            <label
              htmlFor="toDate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              To
            </label>
            <input
              type="date"
              id="toDate"
              className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              // defaultValue={seletedUserData?.name}
              name="toDate"
              onChange={handleChnage}
            />
          </div>
        </div>
        <button
          className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2"
          onClick={handleGenerateAll}
        >
          Generate All
        </button>
      </div>
      <hr className="my-6"/>
      <div>
        <h3 className="text-1xl font-bold py-2 "> Initiated Orders</h3>
        <div className="grid gap-6 mb-1 md:grid-cols-2">
          <div>
            <label
              htmlFor="fromDate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              From
            </label>
            <input
              type="date"
              id="fromDate"
              className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              // defaultValue={seletedUserData?.name}
              name="fromDate"
              onChange={handleChnage}
            />
          </div>
          <div>
            <label
              htmlFor="toDate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              To
            </label>
            <input
              type="date"
              id="toDate"
              className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              // defaultValue={seletedUserData?.name}
              name="toDate"
              onChange={handleChnage}
            />
          </div>
        </div>
        <button
          className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2"
          onClick={handleGenerateInitiated}
        >
          Generate Initiated
        </button>
      </div>
      <hr className="my-6"/>
      <div>
        <h3 className="text-1xl font-bold py-2"> Approved Order</h3>
        <div className="grid gap-6 mb-1 md:grid-cols-2">
          <div>
            <label
              htmlFor="fromDate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              From
            </label>
            <input
              type="date"
              id="fromDate"
              className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              // defaultValue={seletedUserData?.name}
              name="fromDate"
              onChange={handleChnage}
            />
          </div>
          <div>
            <label
              htmlFor="toDate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              To
            </label>
            <input
              type="date"
              id="toDate"
              className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              // defaultValue={seletedUserData?.name}
              name="toDate"
              onChange={handleChnage}
            />
          </div>
        </div>
        <button
          className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2"
          onClick={handleGenerateApproved}
        >
          Generate Approved
        </button>
      </div>
      <hr className="my-6"/>
      <div>
        <h3 className="text-1xl font-bold py-2"> Shipped Orders</h3>
        <div className="grid gap-6 mb-1 md:grid-cols-2">
          <div>
            <label
              htmlFor="fromDate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              From
            </label>
            <input
              type="date"
              id="fromDate"
              className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              // defaultValue={seletedUserData?.name}
              name="fromDate"
              onChange={handleChnage}
            />
          </div>
          <div>
            <label
              htmlFor="toDate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              To
            </label>
            <input
              type="date"
              id="toDate"
              className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              // defaultValue={seletedUserData?.name}
              name="toDate"
              onChange={handleChnage}
            />
          </div>
        </div>
        <button
          className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2"
          onClick={handleGenerateShipped}
        >
          Generate Shipped
        </button>
      </div>
      
      <ToastContainer autoClose={3000}/>
      </RenderHirePurchasePage>
    </div>
  )
}

export default HirePurchaseReport