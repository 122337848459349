/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import Loader from "../../Components/Loader";
import { dataActionCreators } from "../../services/Actions";
import RenderAdminPage from "../RenderAdminPage";
import Chart from "../Components/Chart";
  
    function ChartPage() {
      const history = useHistory();
    const dispatch = useDispatch();
    const { GetLoansForStatistics } = bindActionCreators(dataActionCreators, dispatch);
    const [formData, setFormData] = useState({
        status: "",
        year: ""
    })
    const data = useSelector((state) => state?.data);
    const {isLoading,  loansStatistics} = data

    useEffect(() => {
        GetLoansForStatistics("paid")
    }, [])

    useEffect(() => {
        GetLoansForStatistics(formData.status)
    }, [formData.status])
  
    return (
      <div className="LoanApplication">
        <RenderAdminPage title={""}>
          {isLoading && <Loader />}
          <div className="mt-4 mb-2">
            <label>Choose status</label>
            <select  className="py-2 px-3 ml-3 rounded-full" onChange={(e) => setFormData({...formData, status: e.target.value})}>
                <option value={"all"}>All</option>
                <option value={"paid"}>Paid</option>
                <option value={"submitted"}>Submitted</option>
                <option value={"booked"}>Booked</option>
                <option value={"initiated"}>Initiated</option>
                <option value={"authorized"}>Authorized</option>
                <option value={"rejected"}>Rejected</option>
            </select>
          </div>
          <Chart loansStatistics={loansStatistics} width={1300} height={400}/>
        </RenderAdminPage>
      </div>
    );
    }
    
    export default ChartPage;
    