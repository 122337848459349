/* eslint-disable  */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { bindActionCreators } from 'redux'
import Loader from '../../Components/Loader'
import { productActionCreators } from '../../services/Actions'
import ProductModal from '../Modal/AddProductModal'
import RenderHirePurchasePage from '../RenderHirePurchasePage'

function RequestedOrders() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { GetRequestOrdersAdmin, SendMessageRequestOrdersAdmin } = bindActionCreators(productActionCreators, dispatch)
    const [openModal, setOpenModal] = useState(false);
    const [oderDetailed, setOderDetailed] = useState({});
    const productState = useSelector(state => state.product)
    const { request_orders, isLoading, response } = productState
    const [message, setMessage] = useState("")


    console.log('productState', productState)

   

    const handleSubmitMessage =(e)=> {
        e.preventDefault()
        const payload = {
            message: message,
            staff_id: oderDetailed?.staff_id
        }
        SendMessageRequestOrdersAdmin(payload, oderDetailed?._id)
        setOpenModal(false)
        console.log(message)
    }

    useEffect(() => {
        GetRequestOrdersAdmin()
    }, [])
    return (
        <>
            <RenderHirePurchasePage>
                {isLoading && <Loader />}
                <div className='Orders'>
                    {request_orders?.length > 0 ? (<>
                        <div className='orderContainer'>
                            {request_orders?.map((order, idx) => {
                                console.log('order', order)
                                return (
                                    <div className='orderCard' key={idx}>
                                        <div className='items'>
                                            <div className='image'>
                                                {/* <img src='/images/dev/del.png' alt='product'/> */}
                                                {order?.images?.length > 0 ? <img src={order?.images[0]?.url} alt='product' /> : <img src='/images/dev/product-no-image.jpeg' alt='product' />}
                                            </div>
                                            <div className='product-name'>
                                                {order?.product_name?.length > 20 ? order?.product_name?.slice(0, 20) + '...' : order?.product_name}
                                            </div>
                                            <div className='price'><small className='text-gray-500'>Staff ID:</small> {order?.staff_id}</div>
                                            {/* <div className='ordered_date'>Ordered on: <span>{moment(order?.created_at).format("MMM Do YY")}</span></div> */}
                                            <button onClick={() => {
                                                setOderDetailed(order)
                                                setOpenModal(true)
                                            }}>
                                                {/* <span><GrProjects size={'14px'} color=""/></span> */}
                                                <span>view</span>
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </>) : (<>Not found</>)}
                </div>

                {openModal && (
                    <ProductModal
                        closeModal={setOpenModal}
                        width="55%"
                        height={"60vh"}
                        overflowY={"scroll"}
                        title={oderDetailed?.product_name}
                    >
                   

                            <div className='item'>
                                <div className='title font-bold'>Product Name:</div>
                                <div>{oderDetailed?.product_name}</div>
                            </div>
                            <div className='item'>
                                <div className='title font-bold'>Product Description:</div>
                                <div>
                                    
                                    {oderDetailed?.description}
                                    </div>
                            </div>
                            <div className='item'>
                                <div className='title  font-bold'>Product Images:</div>
                                <div className='images'>
                                    {oderDetailed?.images.length > 0 ? (oderDetailed?.images?.map((img, idx) => {
                                        return (
                                            <div key={idx + 1} style={{ display: 'inline-block',flexDirection: 'row'  }}><img style={{width: '100px'}}  src={img?.url} alt='' /></div>
                                        )
                                    })) : (<img style={{width: '100px'}} src='/images/dev/product-no-image.jpeg' alt='No Imaage'/>)}
                                </div>
                            </div>

                            <div className='notificationSection'>
                                <h1 className='font-bold text-2xl my-2'>Send Message</h1>
                                <form>
                                <textarea id='description'  name='description' onChange={(e) => setMessage(e.target.value)} rows={3} className='form-control' placeholder='Type the description including product specification here'></textarea>
                                    <div>
                                    <button className='customBtn' onClick={handleSubmitMessage}>Send Message</button>
                                    <button onClick={() => setOpenModal(false)} className='backbtn'>close</button>

                                    </div>
                                </form>
                            </div>






                            <div className="modalButton">

                            </div>
                     
                    </ProductModal>
                )}
            </RenderHirePurchasePage>
        </>
    )
}

export default RequestedOrders