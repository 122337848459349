/* eslint-disable  */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { messageActionCreators } from '../../services/Actions';
import RenderHirePurchasePage from '../RenderHirePurchasePage'
import moment from 'moment';
import './HirePurchaseMessage.css'

function HirePurchaseMessage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { GetMessagesByDepartment, MarkMessageAsRead } = bindActionCreators(
      messageActionCreators,
      dispatch
    );
    const message = useSelector((state) => state?.message);
    const { isLoading, messages, response } = message;
  
    console.log("messages", messages);
  
    useEffect(() => {
      // GetConversations()
      GetMessagesByDepartment("Mart Officer");
    }, []);
  return (
    <div className='HirePurchaseMessage'>
        <RenderHirePurchasePage>
        <div className="LoansMessages">
        <div className="card">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Member Info</th>
                <th scope="col">Ref#</th>
                <th scope="col">Subject</th>
                <th scope="col">Department</th>
                {/* <th scope="col">Status</th> */}
                <th scope="col">last update</th>
              </tr>
            </thead>
            <tbody>
              {messages?.length > 0 ? (
                messages?.map((message, index) => {
                  let chatArr = message?.chat;
                  let last = chatArr.at(-1)
                  console.log('last', last)

                  return (
                    <tr
                      key={index}
                      onClick={() =>{
                        MarkMessageAsRead(message?._id, last?._id);
                        history.push({
                          pathname: "/mart/messages-detailed",
                          state: { chat_id: message?._id },
                        })
                      }
                      }
                    >
                        <td className="px-1 py-3 border-b border-gray-200 bg-white text-sm">
                        <div className="flex">
                          <div className="flex-shrink-0 w-10 h-10">
                            {message?.userData?.profile_image ? (
                              <img
                                className="w-full h-full rounded-full"
                                src={message?.userData?.profile_image}
                                alt=""
                              />
                            ) : (
                              <>
                                {message?.userData?.name?.split(" ")[0] ===
                                  "Mr." ? (
                                  <img
                                    className="w-full h-full rounded-full"
                                    src="/images/dev/avarta2.jpeg"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="w-full h-full rounded-full"
                                    src="/images/dev/woman-avarta.jpeg"
                                    alt=""
                                  />
                                )}
                              </>
                            )}
                          </div>
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {message?.userData?.name}
                            </p>
                            <p className="text-bold text-gray-600 whitespace-no-wrap">
                              {message?.userData?.staff_id}
                            </p>
                          </div>
                        </div>
                      </td>
                       <td className={`${last?.system_user_read ? '' : 'font-bold'}`}>{message?.reference}</td>
                      {/* <th scope="row">{message?.reference}</th> */}
                      <td>
                      <div className={`${last?.system_user_read ? '' : 'font-bold'}`}>{message?.subject}</div>
                        <p className="text-gray-400">
                          {last?.message}
                        </p>
                      </td>
                      <td>{message?.system_user}</td>
                      {/* <td>{message?.status}</td> */}
                      <td>{moment(message?.updated_at).format("MMM Do YY")}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>No Message Avaialable</tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
        </RenderHirePurchasePage>
    </div>
  )
}

export default HirePurchaseMessage