/* eslint-disable  */
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { dataActionCreators } from "../../services/Actions";
import RenderAdminPage from "../RenderAdminPage";
import "./LoanBookings.css";
import PromptModal from "../Components/PromptModal";
import Loader from "../../Components/Loader";

function AdminLoanApplicationDetailed() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  console.log("location", location);
  const loanDetailed = location?.state?.loan;
  const defaultImage = "/images/dev/success.png";
  const {
    MarkedAsPaidAppliedLoans,
    ResetDataResponse,
    MarkedAsRejectedAppliedLoansByAdmin
  } = bindActionCreators(dataActionCreators, dispatch);
  const data = useSelector((state) => state?.data);
  const response = data?.response;
  console.log("loanDetailed", loanDetailed);

  const [openPromptRejectModal, setPromptOpenRejectModal] = useState(false);
  const [error, setError] = useState("");
  const [rejectMessage, setRejectedMessage] = useState("");


  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      setTimeout(() => {
        ResetDataResponse();
        history.goBack();
        // history.push("/admin/loans-paid");
      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetDataResponse();
        // history.push('')
      }, 1500);
    }
  }, [response?.state]);
  
  return (
    <div className="LoanApplicationDetailed">
      <RenderAdminPage title={""}>
        {data.isLoading && <Loader/>}
        <div className="flex justify-between">
          <div
            className="relative inline-block px-3 my-2 mx-2 py-1 font-semibold text-white leading-tight cursor-pointer"
            onClick={() => history.goBack()}
          >
            <span
              aria-hidden
              className="absolute inset-0 bg-gray-900 opacity-50 rounded-full"
            ></span>
            <span className="relative">Back</span>
          </div>
        </div>

        <div
          className="loanDetailed_profile_container"
          style={{
            background: `url(${loanDetailed?.user?.profile_image
                ? loanDetailed?.user?.profile_image
                : "/images/dev/del.png"
              }) no-repeat center center/cover`,
          }}
        >
          <div className="overlay ">
            {/* <h2>User Information</h2> */}
            <div className="profile">
              <div className="items">
                <div className="image">
                  <img
                    src={
                      loanDetailed?.user?.profile_image
                        ? loanDetailed?.user?.profile_image
                        : "/images/dev/del.png"
                    }
                    alt="product"
                  />
                </div>
                <div className="profile-name">{loanDetailed?.user?.name}</div>

              </div>
              <div
                className="pb-3"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >

                {loanDetailed?.status === "paid" ? (
                  <></>
                  // <button
                  //   className="bg-blue-700 px-2 py-1 rounded-full text-white"
                  //   onClick={() => {
                  //     const body = {
                  //       status: true,
                  //     };
                  //     MarkedAsInitiatedAppliedLoans(loanDetailed?._id, body);

                  //   }}
                  // >
                  //   Mark as initiated
                  // </button>
                ) : (
                  <button
                    className="bg-green-700 px-2 py-1 rounded-full text-white"
                    onClick={() => {
                      const body = {
                        status: true,
                      };

                      MarkedAsPaidAppliedLoans(loanDetailed?._id, body);

                    }}
                  >
                    Mark as paid
                  </button>
                )}

              </div>
            </div>
          </div>
        </div>

        <div className="grid gap-6 mb-1 md:grid-cols-2 bg-white px-2 py-10">
          <div className="sideOne">
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Full Name</div>
              <div className="value">{loanDetailed?.user?.name}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Staff ID</div>
              <div className="value">{loanDetailed?.user?.staff_id}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Facility</div>
              <div className="value">{loanDetailed?.user?.facility}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Email Address</div>
              <div className="value">{loanDetailed?.email}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Phone Number</div>
              <div className="value">{loanDetailed?.personal_phone}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Date Of Birth</div>
              <div className="value">{loanDetailed?.dateOfBirth}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Age</div>
              <div className="value">{loanDetailed?.age}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Residential Address 1</div>
              <div className="value">
                <p>{loanDetailed?.residential_address1}</p>
                <p>{loanDetailed?.residential_address2}</p>
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Work Address</div>
              <div className="value">
                <p>{loanDetailed?.work_address1}</p>
                <p>{loanDetailed?.work_address2}</p>
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Work Phone</div>
              <div className="value">{loanDetailed?.work_phone}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">ID Card Type</div>
              <div className="value">{loanDetailed?.IdCardType}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">ID Number</div>
              <div className="value">{loanDetailed?.IDCardNumber}</div>
            </div>

            <hr />
            <h3>Guarantor 1</h3>

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Full name</div>
              <div className="value">
                {loanDetailed?.guarantor_name}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Guarantor Phone</div>
              <div className="value">{loanDetailed?.guarantor_phone}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Guarantor Staff ID</div>
              <div className="value">{loanDetailed?.guarantor_staff_id}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Ghana Card Number</div>
              <div className="value">
                {loanDetailed?.guarantor_ghana_card}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Ghana GPS Addressr</div>
              <div className="value">
                {loanDetailed?.guarantor_gps}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Occupation</div>
              <div className="value">
                {loanDetailed?.guarantor_occupation}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Mandate Number</div>
              <div className="value">
                {loanDetailed?.guarantor_mandate_number}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">OTP Number</div>
              <div className="value">
                {loanDetailed?.guarantor_otp_number}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Relationship</div>
              <div className="value">
                {loanDetailed?.guarantor_relationship}
              </div>
            </div>
          </div>

          <div className="sideTwo">
            <h3>Guarantor 2</h3>

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Full name</div>
              <div className="value">
                {loanDetailed?.guarantor_name2}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Guarantor Phone</div>
              <div className="value">{loanDetailed?.guarantor_phone2}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Guarantor Staff ID</div>
              <div className="value">{loanDetailed?.guarantor_staff_id2}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Ghana Card Number</div>
              <div className="value">
                {loanDetailed?.guarantor_ghana_card2}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Ghana GPS Addressr</div>
              <div className="value">
                {loanDetailed?.guarantor_gps2}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Occupation</div>
              <div className="value">
                {loanDetailed?.guarantor_occupation2}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Mandate Number</div>
              <div className="value">
                {loanDetailed?.guarantor_mandate_number2}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">OTP Number</div>
              <div className="value">
                {loanDetailed?.guarantor_otp_number2}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Relationship</div>
              <div className="value">
                {loanDetailed?.guarantor_relationship2}
              </div>
            </div>
            <hr />
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Loan Amount</div>
              <div className="value">{loanDetailed?.loanAmount}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Loan Term</div>
              <div className="value">{loanDetailed?.loanTerm}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Affordability</div>
              <div className="value">{loanDetailed?.affordability}</div>
            </div>

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Mandate Number</div>
              <div className="value">{loanDetailed?.mandateNumber}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">OTP Number</div>
              <div className="value">{loanDetailed?.otpNumber}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Monthly Deduction</div>
              <div className="value">{loanDetailed?.monthlyDeduction}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Loan Purpose</div>
              <div className="value">{loanDetailed?.purposeForLoan}</div>
            </div>

            <hr />
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">SNNIT Number</div>
              <div className="value">{loanDetailed?.ssnit}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Bank Name</div>
              <div className="value">{loanDetailed?.bank_name}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Account Holder Name</div>
              <div className="value">
                {loanDetailed?.bank_account_holder_name}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Bank Branch</div>
              <div className="value">{loanDetailed?.bank_branch}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Account Number</div>
              <div className="value">{loanDetailed?.bank_account_number}</div>
            </div>
            <hr />

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Employer Name</div>
              <div className="value">{loanDetailed?.employer_name}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Employee Start Date</div>
              <div className="value">{loanDetailed?.employment_start_date}</div>
            </div>

            <div
              className="item grid  mb-3 md:grid-cols-2"
              style={{
                alignItems: "center",
              }}
            >
              <img src={loanDetailed?.frontIDImage} alt="id_front" />
              <img src={loanDetailed?.passportImage} alt="id_back" />
            </div>


          </div>
        </div>

        {openPromptRejectModal && (
          <PromptModal
            closeModal={setPromptOpenRejectModal}
            width={"auto"}
            title="Reject loan to officers"
          >
            <div className="relative  ">
              <div className="p-6 text-center">
                {error && (
                  <div className=" mb-2">
                    <a className="text-red-400 border px-2 py-1 rounded-full">
                      {error}
                    </a>
                  </div>
                )}
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Rejected message
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    onChange={(e) => setRejectedMessage(e.target.value)}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write the reason for rejecting loan here. The loan officers will see this..."
                  ></textarea>
                </div>

                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  onClick={() => {
                    if (rejectMessage === "") {
                      setError("Please add message first");

                      setTimeout(() => {
                        setError("");
                      }, 5000);
                    } else {
                      //mark as rejected
                      const payload = {
                        status: true,
                        message: rejectMessage,
                        officerRejected: true,
                        rejectedBy: 'ADMIN'
                      };

                      MarkedAsRejectedAppliedLoansByAdmin(loanDetailed?._id,
                        payload);
                      setRejectedMessage("");

                      setPromptOpenRejectModal(false);
                    }
                  }}
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  Reject
                </button>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => setPromptOpenRejectModal(false)}
                >
                  No, cancel
                </button>
              </div>
            </div>
          </PromptModal>
        )}

        <ToastContainer autoClose={3000} />
      </RenderAdminPage>
    </div>
  );
}

export default AdminLoanApplicationDetailed;
