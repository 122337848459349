/* eslint-disable  */
import React from 'react'
import './Modal.css'

function PromptModal({children, width, height, title, closeModal, submitFunction}) {
    const handleSubmit = () => {
      // submitFunction()
      alert('he')
    }
    return (
      <div className="Modal">
            <div className="modalContainer" style={{width: `${width}`, height: `${height}`, overflow: 'scroll'}}>
              <div className="flex justify-between items-start p-2 rounded-t border-b ">
                <h3 className="text-xl font-semibold ">
                  {title}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={() => closeModal(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="modalBody p-2 space-y-3">
                  {children}
              </div>
         
            </div>
          </div>
  
    )
  }

export default PromptModal