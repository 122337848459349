/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { productActionCreators } from "../../services/Actions";
import axiosInstance from "../../services/Axios/axios";
import "../css/hirepurchase.css";
import RenderHirePurchasePage from "../RenderHirePurchasePage";
import Loader from "../../Components/Loader";
import "./OrderDetailed.css";
import { toast, ToastContainer } from "react-toastify";
import PromptModal from "../../AdminModel/Components/PromptModal";
import { MdOutlineSimCardDownload } from "react-icons/md";
import FileSaver from "file-saver";
import { Message } from "@material-ui/icons";
import Modal from "../../AdminModel/Components/Modal";
import { sendMessageToMembersBySystemUser } from "../../services/Actions/MessagesAction";

function OrderDetailed() {
  const history = useHistory();
  const dispatch = useDispatch();
  console.log("history", history);
  const order = history.location.state;
  const productStore = useSelector((state) => state.product);
  const { isLoading, response } = productStore;
  const [openPromptRejectModal, setPromptOpenRejectModal] = useState(false);
  const [error, setError] = useState("");
  const [rejectMessage, setRejectedMessage] = useState("");
  const [checkedFormEdit, setCheckedFormEdit] = useState(false);

  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [memberMessage, setMemberMessage] = useState("");

  console.log("order", order);

  let timestamp = new Date().getTime();

  const {
    UpdateOrderToInitiated,
    UpdateOrderToRejected,
    UpdateOrderToShipped,
    UpdateOrderToApproved,
    ResetProductResponse,
  } = bindActionCreators(productActionCreators, dispatch);



  const handleSendMemberMessage = () => {
    try {
      // console.log('memberMessage', memberMessage)
      // alert(1)
      if(memberMessage == ""){
        toast.error("Please message is required")
      }else{
        //
        const payload = {
          message: memberMessage,
          member_staff_id: order?.staff_id,
          system_user: "Mart Officer"
          
          // user_id: auth?.user?._id, 

          // user_id: auth?.user?._id, 
          // member_profile: auth?.user?.profile_image,
          // reference: "",
        };
        dispatch(sendMessageToMembersBySystemUser(payload));
        console.log('payload', payload)
        setOpenMessageModal(false);
      }
    } catch (error) {
      
    }
  }

  

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      setTimeout(() => {
        ResetProductResponse();
        history.goBack();
      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetProductResponse();
      }, 1500);
    }
  }, [response?.state, response?.message]);

  return (
    <div className="OrderDetailed">
      <RenderHirePurchasePage>
        {isLoading && <Loader />}
        <button className="backbtn" onClick={() => history.goBack()}>
          Back
        </button>
        <div
          className="user_profile_section"
          style={{
            background: `url(${
              order?.user_id?.profile_image
                ? order?.user_id?.profile_image
                : "/images/dev/del.png"
            }) no-repeat center center/cover`,
          }}
        >
          <div className="overlay">
            {/* <h2>User Information</h2> */}
            <div className="profile">
              <div className="items">
                <div className="image">
                  <img src={order?.user_id?.profile_image} alt="product" />
                </div>
                <div className="profile-name">{order?.user_id?.name}</div>
                <div className="staff_id">
                  Staff Id: <span>{order?.user_id?.staff_id}</span>
                </div>

                <div className="ordered_date">
                  Facility: <span>{order?.user_id?.facility}</span>
                </div>
                <button
                  onClick={() =>
                    history.push({
                      pathname: "/mart/member-detailed",
                      state: { member: order?.user_id?.staff_id },
                    })
                  }
                >
                  view detail
                </button>
                  <br/>
                <button
                    className="approvedBtn  px-2 py-1 rounded-full text-white"
                    // type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      setOpenMessageModal(true);
                    }}
                  >
                   <span><Message/></span> Message member
                  </button>


              </div>

              <div
                className="pb-3"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >

                {
                  order.status === "submitted" && (
                    <button
                    className="processBtn"
                    onClick={() => {
                      const payload = {
                        status: true,
                      };
                      UpdateOrderToInitiated(order?._id, payload);
                    }}
                  >
                    Book
                  </button>
                  )
                }

                {
                  order.status === "initiated" && (
                    <button
                    className="approvedBtn"
                    onClick={() => {
                      const payload = {
                        status: true,
                      };
                      UpdateOrderToApproved(order?._id, payload);
                    }}
                  >
                    approved
                  </button>
                  )
                }

              
                {
                  order.status === "approved" && (
                    <button
                    className="shippedBtn"
                    onClick={() => {
                      const payload = {
                        status: true,
                      };
                      UpdateOrderToShipped(order?._id, payload);
                    }}
                  >
                    shipped
                  </button>
                  )
                }
        

            {
              order.status === "shipped" ? (<></>) : ( <button
                className="rejectBtn"
                onClick={() => {
                  setPromptOpenRejectModal(true);
                  // const payload = {
                  //   status: true,
                  // };
                  // UpdateOrderToRejected(order?._id, payload);
                }}
              >
                reject
              </button>)
            }

               
              </div>
            </div>
          </div>
        </div>
        {order?.order?.map((ord, idx) => {
          console.log("ord", ord);
         
            let selectedDuration;
            let percentChange;
      
            if (Number(ord.period) === 24) {
              percentChange = 1.2
              selectedDuration = Number(ord.period);
            } else if (Number(ord.period) === 18) {
              percentChange = 1.145
              selectedDuration = Number(ord.period);
            } else if (
              
              Number(ord.period) === 3 ||
              Number(ord.period) === 6 ||
              Number(ord.period) === 12
            ) {
              percentChange = 1.1
              selectedDuration = Number(ord.period); // 10%
            }
      
            const adminCharge = 3 / 100;
            const retailPrice = Number(ord?.product_price)
            const adminAmount = retailPrice * adminCharge;
            const initialAmount = (retailPrice * percentChange) + adminAmount;
        
        
          
           
        
            // setChangedPrice(Number(initialAmount / selectedDuration).toFixed(2))
            // setChangedPrice(product?.new_price)
            let monthlyDedNew = Number(initialAmount / selectedDuration).toFixed(2);
        
            // OLD PRICE
            // const retailOldPrice = Number(product?.old_price)
            // const adminOldAmount = retailOldPrice * adminCharge;
            // const initialOldAmount = (retailOldPrice * percentChange) + adminOldAmount;
        
        
        
            // setChangedOldPrice(Number(initialOldAmount / selectedDuration).toFixed(2))
        
       
          return (
            <div key={idx} className="orderCover">
              <img
                src={
                  ord?.product_img
                    ? ord?.product_img[0]?.url
                    : "/images/dev/product_placeholder.png"
                }
                alt="image"
                className="product_image"
              />
              <div className="product_info">
             
                {ord?.product_quantity > 0 ? (
                  <p className="stock">In stock</p>
                ) : (
                  <p className="stock">Out of stock</p>
                )}

                {/* <h3 className="name">{ord?.product_name}</h3> */}
                <p className="proce">
                  Product Name: <span>{ord?.product_name}</span>
                </p>
                <p className="proce">
                  Period: <span>{ord?.period}</span>
                </p>
                <p className="proce">
                  Actual Price: <span>{ord?.product_price}</span>
                </p>
                <p className="proce">
                  Price: <span>{monthlyDedNew}</span>
                  {/* Price: <span>{ord?.period_price}</span> */}
                </p>
                <div className="qty">
                  <div>
                    Quantity: <span>{ord?.qty}</span>
                  </div>
                </div>
                <div className="color">
                  <div>
                    Colors: <span>{ord?.color}</span>
                  </div>
                </div>
                <div className="size">
                  Size: <span>{ord?.size}</span>
                </div>
                <div
                  className="btn "
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    className="customBtn px-2"
                    onClick={() =>
                      history.push({
                        pathname: "/mart/product-detail",
                        state: { product_id: ord?.product_id },
                      })
                    }
                  >
                    View Product
                  </button>
                </div>
              </div>
            </div>
          );
        })}

        <div className="order_detailed">
          <h1>Order Detailed</h1>
          <div className="grid gap-6 mb-1 md:grid-cols-2 bg-white px-2 py-10">
            <div className="sideOne">
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Email Address</div>
                <div className="value">{order?.email}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Phone Number</div>
                <div className="value">{order.personal_phone}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Staff ID</div>
                <div className="value">{order.staff_id}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Facility</div>
                <div className="value">{order.facility}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Work Phone</div>
                <div className="value">{order.work_phone}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Date of Birth</div>
                <div className="value">{order?.dateOfBirth}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Age</div>
                <div className="value">{order?.age}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Gender</div>
                <div className="value">{order?.gender}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name"> Address Line 1</div>
                <div className="value">{order?.residential_address1}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Address Line 2</div>
                <div className="value">{order?.residential_address2}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">SSNIT</div>
                <div className="value">{order?.ssnit}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Occupation</div>
                <div className="value">{order?.occupation}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">ID Card Type</div>
                <div className="value">{order?.IdCardType}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">ID Card Number</div>
                <div className="value">{order?.IDCardNumber}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Affordability</div>
                <div className="value">{order?.affordability}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Employer</div>
                <div className="value">{order?.employer_name}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Employer Start Date</div>
                <div className="value">{order?.employment_start_date}</div>
              </div>
              {/* <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Division</div>
                <div className="value">{order?.division}</div>
              </div> */}
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Work Address</div>
                <div className="value">{order?.work_address1}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Work Address 2</div>
                <div className="value">{order?.work_address2}</div>
              </div>
              <hr/>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Mandate Number 1</div>
                <div className="value">{order?.mandate_number}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">OTP Number 1</div>
                <div className="value">{order?.otp_number}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name"> Mandate Number 2</div>
                <div className="value">{order?.mandate_number2}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">OTP Number 2</div>
                <div className="value">{order?.otp_number2}</div>
              </div>
            </div>

            <div className="sideTwo">
              <h3 className="text-green-700 pb-2">Guarantor 1</h3>

              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Full name</div>
                <div className="value">{order?.guarantor_name}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Guarantor Phone</div>
                <div className="value">{order?.guarantor_phone}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Guarantor Staff ID</div>
                <div className="value">{order?.guarantor_staff_id}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Ghana Card Number</div>
                <div className="value">{order?.guarantor_ghana_card}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Ghana GPS Addressr</div>
                <div className="value">{order?.guarantor_gps}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Occupation</div>
                <div className="value">{order?.guarantor_occupation}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Mandate Number</div>
                <div className="value">{order?.guarantor_mandate_number}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">OTP Number</div>
                <div className="value">{order?.guarantor_otp_number}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Relationship</div>
                <div className="value">{order?.guarantor_relationship}</div>
              </div>

              {/* Guaranta two */}
              <h3 className="text-green-700 pb-2">Guarantor 2</h3>


              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Full name</div>
                <div className="value">{order?.guarantor_name2}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Guarantor Phone</div>
                <div className="value">{order?.guarantor_phone2}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Guarantor Staff ID</div>
                <div className="value">{order?.guarantor_staff_id2}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Ghana Card Number</div>
                <div className="value">{order?.guarantor_ghana_card2}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Ghana GPS Addressr</div>
                <div className="value">{order?.guarantor_gps2}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Occupation</div>
                <div className="value">{order?.guarantor_occupation2}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Mandate Number</div>
                <div className="value">{order?.guarantor_mandate_number2}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">OTP Number</div>
                <div className="value">{order?.guarantor_otp_number2}</div>
              </div>
              <div className="item grid  mb-3 md:grid-cols-2">
                <div className="name">Relationship</div>
                <div className="value">{order?.guarantor_relationship2}</div>
              </div>
                  <h1>Ghana Identification Card</h1>
              <div className="id_card_box">
                  {/* {console.log('order-new', order)} */}
                  <div className="imgCover">
                   <div className="imgBox">
                   <img src={order?.frontIDImage+'?'+timestamp} alt="front-image"/>
                   </div>
                    {/* <a href={order?.frontIDImage} download={true} target="_blank"><MdOutlineSimCardDownload/></a> */}
                    <MdOutlineSimCardDownload className="download_btn cursor-pointer" onClick={() => {
                      FileSaver.saveAs(order?.frontIDImage, 'image.jpg')
                    }}/>
                  </div>
                  <div className="imgCover">
                   <div className="imgBox">
                   <img src={order?.passportImage+'?'+timestamp} alt="back-image"/>
                   </div>
                    <MdOutlineSimCardDownload className="cursor-pointer download_btn" onClick={() => {
                      FileSaver.saveAs(order?.passportImage+'?'+timestamp, 'image.jpg')
                    }}/>
                  </div>
              </div>
            </div>
          </div>
        </div>





        {openPromptRejectModal && (
          <PromptModal
            closeModal={setPromptOpenRejectModal}
            width={"auto"}
            title="Reject"
          >
            <div className="relative  ">
              <div className="p-6 text-center">
                {error && (
                  <div className=" mb-2">
                    <a className="text-red-400 border px-2 py-1 rounded-full">
                      {error}
                    </a>
                  </div>
                )}
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Rejected message
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    onChange={(e) => setRejectedMessage(e.target.value)}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write the reason for rejecting loan here. The member will see this..."
                  ></textarea>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    checked={checkedFormEdit}
                    onChange={() => setCheckedFormEdit(!checkedFormEdit)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Allow member to <strong>Edit Form</strong>
                  </label>
                </div>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  onClick={() => {
                    if (rejectMessage === "") {
                      setError("Please add message first");

                      setTimeout(() => {
                        setError("");
                      }, 5000);
                    } else {
                      //mark as rejected
                      const payload = {
                        status: true,
                        message: rejectMessage,
                        resubmit: false,
                        editFormChecked: checkedFormEdit,
                        completed: false,
                      };
 
                    UpdateOrderToRejected(order?._id, payload);
                     
                      setRejectedMessage("");
                      setCheckedFormEdit(false);
                      setPromptOpenRejectModal(false);
                    }
                  }}
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  Reject
                </button>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => setPromptOpenRejectModal(false)}
                >
                  No, cancel
                </button>
              </div>
            </div>
          </PromptModal>
        )}

{openMessageModal && (
        <Modal
          width={"40%"}
          title={"Send Message to member"}
          closeModal={setOpenMessageModal}
        >
          <div className="modalBody">
            <div className="message">
            <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Message to member
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    onChange={(e) => setMemberMessage(e.target.value)}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your message here"
                  ></textarea>
            </div>
          </div>
          <div className="modalButton">
            <div className="btnCover">
              {/* <button type="button" className="modalBtn" onClick={handleSubmit}>
            Change
          </button> */}

              <button
                data-modal-toggle=""
                type="button"
                onClick={() => handleSendMemberMessage()}
                className="cancelBtn text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2.5 text-center mr-2"
              >
                Send Message
              </button>
              <button
                data-modal-toggle=""
                type="button"
                onClick={() => setOpenMessageModal(false)}
                className="cancelBtn text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2.5 text-center mr-2"
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}

        <ToastContainer autoClose={3000} />
      </RenderHirePurchasePage>
    </div>
  );
}

export default OrderDetailed;
