import { messageConstants } from "../Constants/messageConstant";

const initialState = {
  message: {},
  loanMessagesUnread: 0,
  martMessagesUnread: 0,
  messageDetailed: {},
  messages: [],
  isLoading: false,
  conversations: [],
  usersForChat: {},
  response: {
    state: null,
    message: "",
    action: "",
  },

};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case messageConstants.LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case messageConstants.GET_MESSAGE_DETAILED:
      return {
        ...state,
        messageDetailed: action.payload.messageDetailed,
      
      };

    case messageConstants.GET_CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload.conversations,
      };
    case messageConstants.GET_MESSAGES:
      return {
        ...state,
        messages: action.payload.messages,
      };
    case messageConstants.LOAN_OFFICERS_UNREAD:
      return {
        ...state,
        loanMessagesUnread: action.payload.loanMessagesUnread,
      };
    case messageConstants.MART_OFFICERS_UNREAD:
      return {
        ...state,
        martMessagesUnread: action.payload.martMessagesUnread,
      };
    case messageConstants.GET_USERS_FOR_CHAT:
      return {
        ...state,
        usersForChat: action.payload.usersForChat,
      };
    
      case messageConstants.RESPONSE:
        return {
          ...state,
          response: {
            state: action.response.state,
            message: action.response.message,
          },
        };
    default:
      return state;
  }
};

export default messageReducer;
