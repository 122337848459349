/* eslint-disable  */
import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IoMdMore } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { productActionCreators } from "../../services/Actions";
import { GetUserProfile } from "../../services/Actions/authActions";
import RenderHirePurchasePage from "../RenderHirePurchasePage";
import * as moment from 'moment'
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
function ProcessedOrder() {
  const history = useHistory()
  const dispatch = useDispatch()
  const {GetInitiatedOrders, ResetProductResponse} = bindActionCreators(productActionCreators, dispatch)
  const product = useSelector(state => state.product)
  const {isLoading, orders, response, paginate} = product
  const [limit, setLimit] = useState(15)

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(0);
  
  console.log('orders', orders)

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(orders?.slice(itemOffset, endOffset));
    setPageCount(Number(paginate?.total));
  }, [itemOffset, itemsPerPage, orders]);

  const handlePageClick = (event) => {
    console.log("event", event);
    GetInitiatedOrders(true, event.selected + 1, limit, null)

    console.log("currentPage", currentPage);

    const newOffset = (event.selected * itemsPerPage) % paginate?.total;

    setItemOffset(newOffset);
  };


useEffect(() => {
  dispatch(GetUserProfile())
  GetInitiatedOrders(true, 1, limit, null)
}, [])

useEffect(()=> {
  GetInitiatedOrders(true, 1, limit, null)
},[limit])


const onSearchTextChange = debounce((e) => {

  if (e?.target?.value) {
       GetInitiatedOrders(
      true,
      1,
      limit,
    
      `${e?.target?.value ? "&search=" + e?.target?.value : ""}`
    );
  
  } else {
    // setSearch(null);
    GetInitiatedOrders(true, 1, limit, null)
  }
}, 800);


useEffect(() => {
  if (response?.state === "SUCCESS") {
    toast.success(response?.message);
    setTimeout(() => {
      ResetProductResponse();
    
    }, 1500);
  } else if (response?.state === "ERROR") {
    toast.error(response?.message);
    setTimeout(() => {
      ResetProductResponse();
     
    }, 1500);
  }
}, [response?.state]);
  return (
    <div className="ProcessedOrder"> 
      <RenderHirePurchasePage>
        {isLoading && <Loader/>}
        <div className="row">
          <div className="col-md-7">
            <label htmlFor="search">Search Query:</label>
            <input
              type={"search"}
              id="search"
              placeholder="search by staff id, name"
              className="form-control"
              onChange={onSearchTextChange}
            />
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-2">
            <label htmlFor="search">List to show:</label>
            <select
              className="form-control"
              onChange={(e) => setLimit(e.target.value)}
            >
              {/* <option value={5}>5</option> */}
              <option value={15}>15</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={300}>300</option>
            </select>
          </div>
        </div>
          <div>
          <div className="pt-3">
                  <h2 className="text-2xl font-semibold leading-tight pb-3">
                    Booked Orders
                  </h2>
                </div>
          <table className="min-w-full leading-normal">
                      <thead>
                        <tr>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Name
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Affordability
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Date
                          </th>
                        
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Facility
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders?.map((order, index) => {
                          // console.log(loan)
                          return (
                        <tr key={index}> 
                          <td className="px-1 py-3 border-b border-gray-200 bg-white text-sm">
                            <div className="flex">
                              <div className="flex-shrink-0 w-10 h-10">
                                <img
                                  className="w-full h-full rounded-full"
                                  src={order?.user_id?.profile_image}
                                  alt=""
                                />
                              </div>
                              <div className="ml-3">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {order?.user_id?.name}
                                </p>
                                <p className="text-gray-600 whitespace-no-wrap">
                                {order?.user_id?.staff_id}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {order?.affordability}
                            </p>
                          
                          </td>
                          <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {moment(order?.created_at).format("MMM Do YY")}
                            </p>
                          
                          </td>
                         
                          <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
        
                              <span className="relative">{order?.facility}</span>
                          </td>
                          <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm text-right">
                            <button
                              type="button"
                              className="inline-block text-gray-500 hover:text-gray-700"
                              onClick={() => history.push({pathname: '/mart/order-detailed', state: order})}
                            >
                              <svg
                                className="inline-block h-6 w-6 fill-current"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z" />
                              </svg>
                            </button>
                          </td>
                        </tr>

                          )
                        })}
                      </tbody>
                    </table>



                  {orders.length > 0 && (
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="page-num"
                      previousLinkClassName="page-num"
                      nextLinkClassName="page-num"
                      activeLinkClassName="active"
                    />
                  )}
          </div>
          <ToastContainer autoClose={3000}/>
      </RenderHirePurchasePage>
    </div>
  );
}

export default ProcessedOrder;
