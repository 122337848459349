/* eslint-disable  */
import React from "react";
import { useHistory } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";



function PrivateRoute({ component: Component, ...rest }) {
  const token = window.localStorage.getItem("token");
  const history  = useHistory()
  function hasJWT(){
    let flag = false
    window.localStorage.getItem("token") ? flag=true : flag=false
    return flag
  }
  return (
    <Route {...rest}
      render={props => (
        hasJWT() ? <Component {...props} /> : <Redirect to={{pathname: '/'}}/>
      )}
    />
    // <Route
    //   {...rest}
    //   component={(props) => {
    //     if (token) {
    //       return <Component {...props} />;
    //     } else {
    //      return history.replace('/')

    //       // return <Redirect to={"/"} />;
    //     }
    //   }}
    // />
  );
}

export default PrivateRoute;
