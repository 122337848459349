/* eslint-disable  */
import React from 'react'

function NotFoundComponent({title}) {
  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        // height: '100vh',
        textAlign: 'center'
       
    }}>
       <div style={{width: '', margin: '0 auto', display:' flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
       <img src={`/not-found.png`} style={{width: '150px'}}/>
        <p style={{fontSize: '1rem', textAlign: 'center', color: 'red'}}>{title}</p>
       </div>
    </div>
  )
}

export default NotFoundComponent