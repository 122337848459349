/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import Loader from "../../Components/Loader";
import { RolesContants } from "../../contants";
import { dataActionCreators } from "../../services/Actions";
import { GetUserProfile } from "../../services/Actions/authActions";
import RenderLoanPage from "../RenderLoanPage";

function ReportLoans() {
  const dispatch = useDispatch();
  const { GetLoanReport, ResetDataResponse } = bindActionCreators(dataActionCreators, dispatch);

  const [formData, setFormData] = useState("");
  const data = useSelector((state) => state?.data);
  const user = useSelector((state) => state?.auth);
  const { isLoading,response } = data;

  const handleChnage = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    dispatch(GetUserProfile());
  }, []);
  const handleGenerateInitiated = () => {
    if (
      user?.role?.includes(RolesContants.LOAN_OFFICER_HEAD, RolesContants.ADMIN)
    ) {
     
      if (!formData.fromDate || !formData.toDate) {
        toast.error("Choose the range. This is required");
      } else {
        GetLoanReport("initiated", formData.fromDate, formData.toDate);
      }
    } else {
      toast.error(
        "Please are not authorize to generate report on initiated loans"
      );
    }
  };
  const handleGenerateBooked = () => {
    if (
      user?.role?.includes(RolesContants.LOAN_OFFICER_HEAD, RolesContants.ADMIN)
    ) {
     
      if (!formData.fromDate || !formData.toDate) {
        toast.error("Choose the range. This is required");
      } else {
        GetLoanReport("booked", formData.fromDate, formData.toDate);
      }
    } else {
      toast.error(
        "Please are not authorize to generate report on Booked loans"
      );
    }
  };

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      setTimeout(() => {
        ResetDataResponse();
      
      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetDataResponse();
       
      }, 1500);
    }
  }, [response?.state]);
  return (
    <RenderLoanPage page={12}>
      {isLoading && <Loader />}
      <h2 className="text-center font-bold text-[2rem]">Generate Report</h2>
      <div>
        <h3 className="text-1xl font-bold py-2 "> Initiated Loans Report</h3>
        <div className="grid gap-6 mb-1 md:grid-cols-2">
          <div>
            <label
              htmlFor="fromDate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              From
            </label>
            <input
              type="date"
              id="fromDate"
              className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              // defaultValue={seletedUserData?.name}
              name="fromDate"
              onChange={handleChnage}
            />
          </div>
          <div>
            <label
              htmlFor="toDate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              To
            </label>
            <input
              type="date"
              id="toDate"
              className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              // defaultValue={seletedUserData?.name}
              name="toDate"
              onChange={handleChnage}
            />
          </div>
        </div>
        <button
          className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2"
          onClick={handleGenerateInitiated}
        >
          Generate Initiated
        </button>
      </div>
      <hr className="my-6"/>
      <div>
        <h3 className="text-1xl font-bold py-2"> Booked Loans Report</h3>
        <div className="grid gap-6 mb-1 md:grid-cols-2">
          <div>
            <label
              htmlFor="fromDate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              From
            </label>
            <input
              type="date"
              id="fromDate"
              className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              // defaultValue={seletedUserData?.name}
              name="fromDate"
              onChange={handleChnage}
            />
          </div>
          <div>
            <label
              htmlFor="toDate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              To
            </label>
            <input
              type="date"
              id="toDate"
              className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              // defaultValue={seletedUserData?.name}
              name="toDate"
              onChange={handleChnage}
            />
          </div>
        </div>
        <button
          className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2"
          onClick={handleGenerateBooked}
        >
          Generate Booked
        </button>
      </div>
      <ToastContainer autoClose={3000}/>
    </RenderLoanPage>
  );
}

export default ReportLoans;
