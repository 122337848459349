/* eslint-disable  */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { messageActionCreators } from "../../services/Actions";
import RenderLoanPage from "../RenderLoanPage";
import Conversation from "./Conversation";
import moment from "moment";
import "./Loan.css";
import "./LoansMessages.css";
import { useHistory } from "react-router-dom";
function LoansMessages() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { GetMessagesByDepartment, MarkMessageAsRead } = bindActionCreators(
    messageActionCreators,
    dispatch
  );
  const message = useSelector((state) => state?.message);
  const { isLoading, messages, response } = message;

  console.log("messages", messages);

  useEffect(() => {
    // GetConversations()
    GetMessagesByDepartment("Loan Officer");
  }, []);

  return (
    <RenderLoanPage>
      <div className="LoansMessages">
        <div className="card">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Member Info</th>
                <th scope="col">Ref#</th>
                <th scope="col">Subject</th>
                <th scope="col">Department</th>
                {/* <th scope="col">Status</th> */}
                <th scope="col">last update</th>
              </tr>
            </thead>
            <tbody>
              {messages?.length > 0 ? (
                messages?.map((message, index) => {
                  let chatArr = message?.chat;
                  let last = chatArr.at(-1)
                  console.log('last', last)
                  let color = message?.isOpen ? "bg-blue-400" : "bg-red-200";

                  return (
                    <tr
                      key={message?._id}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        MarkMessageAsRead(message?._id, last?._id);
                        history.push({
                          pathname: "/loans/messages-detailed",
                          state: { chat_id: message?._id },
                        })
                      }
                      }
                    >
                      <td className="px-1 py-3 border-b border-gray-200 bg-white text-sm">
                        <div className="flex">
                          <div className="flex-shrink-0 w-10 h-10">
                            {message?.userData?.profile_image ? (
                              <img
                                className="w-full h-full rounded-full"
                                src={message?.userData?.profile_image}
                                alt=""
                              />
                            ) : (
                              <>
                                {message?.userData?.name?.split(" ")[0] ===
                                  "Mr." ? (
                                  <img
                                    className="w-full h-full rounded-full"
                                    src="/images/dev/avarta2.jpeg"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="w-full h-full rounded-full"
                                    src="/images/dev/woman-avarta.jpeg"
                                    alt=""
                                  />
                                )}
                              </>
                            )}
                          </div>
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {message?.userData?.name}
                            </p>
                            <p className="text-bold text-gray-600 whitespace-no-wrap">
                              {message?.userData?.staff_id}
                            </p>
                          </div>
                        </div>
                      </td>

                      <td className={`${last?.system_user_read ? 'text-gray-500' : 'font-bold'}`}>{message?.reference}</td>
                      <td>
                        <div className={`${last?.system_user_read ? 'text-gray-500' : 'font-bold'}`}>{message?.subject}</div>
                        <p className="text-gray-400">
                          {last?.message}
                        </p>
                      </td>
                      <td>{message?.system_user}</td>
                      {/* <td className="rounded-full bg-red-400">
                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                            <span
                              aria-hidden
                              className={`absolute inset-0 ${color} rounded-full`}
                            ></span>
                            <span className="relative">
                              {message?.isOpen ? "Open" : "Closed"}
                            </span>
                          </span>
                        </td> */}
                      <td>{moment(message?.updated_at).format("MMM Do YY")}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>No Message Avaialable</tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </RenderLoanPage>
  );
}

export default LoansMessages;
