/* eslint-disable  */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { productActionCreators } from "../../services/Actions";
import axiosInstance from "../../services/Axios/axios";
import "../css/hirepurchase.css";
import RenderHirePurchasePage from "../RenderHirePurchasePage";
import Loader from "../../Components/Loader";
import "./OrderDetailed.css";
import { toast, ToastContainer } from "react-toastify";
import { GetOneMember } from "../../services/Actions/dataAction";

function MartMemberDetailed() {
  const history = useHistory();
  const dispatch = useDispatch();
  console.log("history", history);
  const member_id = history.location.state.member;
  const productStore = useSelector((state) => state.product);

  const { isLoading, response, member } = useSelector((state) => state.data);

  const { ResetProductResponse } = bindActionCreators(
    productActionCreators,
    dispatch
  );

  console.log("member_id", member_id);

  useEffect(() => {
    dispatch(GetOneMember(member_id));
  }, [member_id]);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      setTimeout(() => {
        ResetProductResponse();
        history.goBack();
      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetProductResponse();
      }, 1500);
    }
  }, [response?.state, response?.message]);

  return (
    <div className="OrderDetailed">
      <RenderHirePurchasePage>
        {isLoading && <Loader />}
        <button className="backbtn" onClick={() => history.goBack()}>
          Back
        </button>
        <div
          className="user_profile_section"
          style={{
            background: `url(${
              member?.cover_image
                ? member?.cover_image
                : "/images/dev/del.png"
            }) no-repeat center center/cover`,
          }}
        >
          <div className="overlay">
            {/* <h2>User Information</h2> */}
            <div className="profile">
              <div className="items">
                <div className="image">
                  <img src={member?.profile_image} alt="product" />
                </div>
                <div className="profile-name">{member?.name}</div>
                <div className="staff_id">
                  Staff Id: <span>{member?.staff_id}</span>
                </div>

                <div className="ordered_date">
                  Facility: <span>{member?.facility}</span>
                </div>
                <button onClick={() => history.goBack()}>
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>



        <div className="grid gap-6 mb-1 md:grid-cols-1 bg-white px-2 py-10">
          <div className="sideOne">
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Full Name</div>
              <div className="value">{member?.name}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">User Name</div>
              <div className="value">{member?.username}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Staff ID</div>
              <div className="value">{member?.staff_id}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Facility</div>
              <div className="value">{member?.facility}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Email Address</div>
              <div className="value">{member?.email}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Phone Number</div>
              <div className="value">{member?.phone_number}</div>
            </div>
           
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Region</div>
              <div className="value">{member?.region}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">District</div>
              <div className="value">{member?.district}</div>
            </div>
           
            
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Date of birth</div>
              <div className="value">{member?.dob}</div>
            </div>
           

            <hr />
    
          </div>

          {/* <div className="sideTwo">
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Loan Amount</div>
              <div className="value">{loanDetailed?.loanAmount}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Loan Term</div>
              <div className="value">{loanDetailed?.loanTerm}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Affordability</div>
              <div className="value">{loanDetailed?.affordability}</div>
            </div>

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Mandate Number</div>
              <div className="value">{loanDetailed?.mandateNumber}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">OTP Number</div>
              <div className="value">{loanDetailed?.otpNumber}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Monthly Deduction</div>
              <div className="value">{loanDetailed?.monthlyDeduction}</div>
            </div>

            <hr />
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">SNNIT Number</div>
              <div className="value">{loanDetailed?.ssnit}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Bank Name</div>
              <div className="value">{loanDetailed?.bank_name}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Account Holder Name</div>
              <div className="value">
                {loanDetailed?.bank_account_holder_name}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Bank Branch</div>
              <div className="value">{loanDetailed?.bank_branch}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Account Number</div>
              <div className="value">{loanDetailed?.bank_account_number}</div>
            </div>
            <hr />

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Employer Name</div>
              <div className="value">{loanDetailed?.employer_name}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Employee Start Date</div>
              <div className="value">{loanDetailed?.employment_start_date}</div>
            </div>

            <div
              className="item grid  mb-3 md:grid-cols-2"
              style={{
                alignItems: "center",
              }}
            >
              <img src={loanDetailed?.frontIDImage} alt="id_front" />
              <img src={loanDetailed?.passportImage} alt="passport photo" />
            </div>

          </div> */}

     
        </div>

        <ToastContainer autoClose={3000} />
      </RenderHirePurchasePage>
    </div>
  );
}

export default MartMemberDetailed;
