import React from 'react'

function CediSymbol() {
  return (
    <>
    <span>&#8373;</span>
    </>
  )
}

export default CediSymbol