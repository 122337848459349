/* eslint-disable  */
import React from 'react'
import { Bar, Tooltip, Area, Line, CartesianGrid, Legend, XAxis, YAxis, ComposedChart } from 'recharts';

function Chart({loansStatistics, width=900, height=350}) {
  console.log('data-stats', loansStatistics)
    const data = [
        {
          "name": "January",
          "uv": 4000,
          "pv": 2400,
          "amt": 2400
        },
        {
          "name": "Febuary",
          "uv": 3000,
          "pv": 1398,
          "amt": 2210
        },
        {
          "name": "Page C",
          "uv": 2000,
          "pv": 9800,
          "amt": 2290
        },
        {
          "name": "Page D",
          "uv": 2780,
          "pv": 3908,
          "amt": 2000
        },
        {
          "name": "Page E",
          "uv": 1890,
          "pv": 4800,
          "amt": 2181
        },
        {
          "name": "Page F",
          "uv": 2390,
          "pv": 3800,
          "amt": 2500
        },
        {
          "name": "Page G",
          "uv": 3490,
          "pv": 4300,
          "amt": 2100
        },
        {
          "name": "Last G",
          "uv": 3490,
          "pv": 4300,
          "amt": 2100
        },
      ]
      
  return (
<ComposedChart width={width} height={height} data={loansStatistics}>
  <XAxis dataKey={"name"} />
  <YAxis />
  <Tooltip />
  <Legend />
  <CartesianGrid stroke="#f5f5f5" />
  {/* <Area type="monotone" dataKey="average" fill="#8884d8" stroke="#8884d8" /> */}
  <Bar dataKey="total" barSize={20} fill="#413ea0" />
  {/* <Line type="monotone" dataKey="tota" stroke="#ff7300" /> */}
</ComposedChart>
  )
}

export default Chart