/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { BiCloudDownload } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import PromptModal from "../../AdminModel/Components/PromptModal";
import Loader from "../../Components/Loader";
import { RolesContants } from "../../contants";
import { dataActionCreators } from "../../services/Actions";
import { GetUserProfile } from "../../services/Actions/authActions";
import RenderLoanPage from "../RenderLoanPage";
import "./Loan.css";
import FileSaver, * as FileServer from "file-saver";
import { MdOutlineSimCardDownload } from "react-icons/md";
import { Message } from "@material-ui/icons";
import Modal from "../../AdminModel/Components/Modal";
import { sendMessageToMembersBySystemUser } from "../../services/Actions/MessagesAction";

function LoanDetailed() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    MarkedAsBookedAppliedLoans,
    MarkedAsInitiatedAppliedLoans,
    MarkedAsAuthorizedAppliedLoans,
    MarkedAsSubmittedLoan,
    MarkedAsRejectedAppliedLoans,
    
    ResetDataResponse,
    MarkedAsRejectedAppliedLoansByLoanHeads,
  } = bindActionCreators(dataActionCreators, dispatch);
  const data = useSelector((state) => state?.data);
  const user = useSelector((state) => state?.auth);
  const response = data?.response;
  console.log("location", location);
  const loanDetailed = location?.state?.loan;
  const defaultImage = "/images/dev/avarta2.jpeg";

  const [openPromptRejectModal, setPromptOpenRejectModal] = useState(false);
  const [openEditLoanFormModal, setOpenEditLoanFormModal]= useState(false);
  const [openPromptRejectByLoanHeadModal, setPromptOpenRejectByLoanHeadModal] =
    useState(false);
  const [error, setError] = useState("");
  const [rejectMessage, setRejectedMessage] = useState("");
  const [checkedFormEdit, setCheckedFormEdit] = useState(false);
  const [formData, setFormData] = useState("")

  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [memberMessage, setMemberMessage] = useState("");


  let timestamp = new Date().getTime();

  console.log("data", data);
  console.log("user", user);
  console.log("loanDetailed", loanDetailed);

  const handleFormChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
  };

  const handleImageDownload = (url, name) => {
    // alert("front image download");
    FileServer.saveAs(url, name);
  };
  const handleFormSaveEdited = () => {
   console.log('formData', formData)
  };

  const handleSendMemberMessage = () => {
    try {
      if(memberMessage == ""){
        toast.error("Please message is required")
      }else{
        //
        const payload = {
          message: memberMessage,
          member_staff_id: loanDetailed?.staff_id,
          system_user: "Loan Officer"
          
          // user_id: auth?.user?._id, 

          // user_id: auth?.user?._id, 
          // member_profile: auth?.user?.profile_image,
          // reference: "",
        };
        dispatch(sendMessageToMembersBySystemUser(payload));
        setOpen(false);
      }
    } catch (error) {
      
    }
  }



  useEffect(() => {
    dispatch(GetUserProfile());
  }, []);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      setTimeout(() => {
        ResetDataResponse();
        history.goBack();
        // history.push('/loans/application')
      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetDataResponse();
        // history.push('')
      }, 1500);
    }
  }, [response?.state]);

  return (
    <div className="LoanApplicationDetailed">
      <RenderLoanPage title={""}>
        {data.isLoading && <Loader />}
        <div className="flex justify-between">
          <div
            className="relative inline-block px-3 my-2 mx-2 py-1 font-semibold text-white leading-tight cursor-pointer"
            onClick={() => history.goBack()}
          >
            <span
              aria-hidden
              className="absolute inset-0 bg-gray-900 opacity-50 rounded-full"
            ></span>
            <span className="relative">Back</span>
          </div>
        </div>

        <div
          className="loanDetailed_profile_container"
          style={{
            background: `url(${loanDetailed?.user?.profile_image
                ? loanDetailed?.user?.profile_image+'?'+timestamp
                : "/images/dev/del.png"
              }) no-repeat center center/cover`,
          }}
        >
          <div className="overlay ">
            {/* <h2>User Information</h2> */}
            <div className="profile">
              <div className="items">
                <div className="image">
                  <img
                    src={
                      loanDetailed?.user?.profile_image
                        ? loanDetailed?.user?.profile_image+'?'+timestamp
                        : "/images/dev/del.png"
                    }
                    alt="product"
                  />
                </div>
                <div className="profile-name">{loanDetailed?.user?.name}</div>
                <button
                    className="approvedBtn  px-2 py-1 rounded-full text-white"
                    // type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      setOpenMessageModal(true);
                    }}
                  >
                   <span><Message/></span> Message member
                  </button>
                {/* <div className="staff_id">
                  Staff Id: <span>{loanDetailed?.user?.staff_id}</span>
                </div>

                <div className="ordered_date">
                  Facility: <span>{loanDetailed?.user?.facility}</span>
                </div> */}
                {/* <button onClick={() => history.push("/mart/order-detailed")}>
                  view detail
                </button> */}
              </div>
              <div
                className="pb-3"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
             {/* {
              loanDetailed?.status !==  "booked" && (
                <button
                className="bg-blue-700 px-2 py-1 rounded-full text-white"
                onClick={() => {
                  if (loanDetailed?.status === "submitted") {
                    toast.error("Already in submitted status");
                  } else {
                    const body = {
                      status: true,
                    };
                    MarkedAsSubmittedLoan(loanDetailed?._id, body);
                  }
                }}
              >
                Submitted
              </button>
              )
             } */}
             {
              loanDetailed?.status === "submitted" && (
                <button
                className="processBtn  px-2 py-1 rounded-full text-white"
                onClick={() => {
                  if (user?.role.includes(RolesContants.LOAN_OFFICER)) {
                    if (loanDetailed?.status === "booked") {
                      toast.error("Already booked");
                    } else {
                      const body = {
                        status: true,
                      };
                      MarkedAsBookedAppliedLoans(loanDetailed?._id, body);
                    }
                  } else {
                    toast.error(`Please you can't perform this operation`);
                  }

                }}
              >
                Book
              </button>
              )
             }
                {loanDetailed?.status === "booked" && (
                  <button
                    className="approvedBtn  px-2 py-1 rounded-full text-white"
                    onClick={() => {
                      // console.log('user?.role', user?.role.includes(RolesContants.LOAN_OFFICER_HEAD))
                      if (
                        user?.role.includes(RolesContants.LOAN_OFFICER_AUTHORIZER)
                      ) {
                        //mark as read
                        const body = {
                          status: true,
                        };
                        MarkedAsAuthorizedAppliedLoans(loanDetailed?._id, body);
                      } else {
                        toast.error(`Please you can't perform this operation`);
                      }
                    }}
                  >
                    Authorize
                  </button>
                )}

                {loanDetailed?.status === "authorized" && (
                  <button
                    className="approvedBtn  px-2 py-1 rounded-full text-white"
                    onClick={() => {
                      // console.log('user?.role', user?.role.includes(RolesContants.LOAN_OFFICER_HEAD))
                      if (
                        user?.role.includes(RolesContants.LOAN_OFFICER_HEAD)
                      ) {
                        //mark as read
                        const body = {
                          status: true,
                        };
                        MarkedAsInitiatedAppliedLoans(loanDetailed?._id, body);
                      } else {
                        toast.error(`Please you can't perform this operation`);
                      }
                    }}
                  >
                    Initiate
                  </button>
                )}

                <button
                  className="rejectBtn  px-2 py-1 rounded-full text-white"
                  onClick={() => {
                    // setPromptOpenRejectByLoanHeadModal(true);
                    if (
                      // user?.role.includes(RolesContants.LOAN_OFFICER_HEAD) && loanDetailed?.status === "booked"
                      user?.role.includes( RolesContants.LOAN_OFFICER_AUTHORIZER)
                    ) {
                      setPromptOpenRejectByLoanHeadModal(true);
                    }else if(user?.role.includes(RolesContants.LOAN_OFFICER_HEAD)){
                      setPromptOpenRejectByLoanHeadModal(true);
                    }else if(user?.role.includes(RolesContants.LOAN_OFFICER)){
                      setPromptOpenRejectModal(true);
                    }

                    // if (
                    //   user?.role.includes(RolesContants.LOAN_OFFICER_HEAD)
                    // ) {
                    //   GetObjectAttributesRequestFilterSensitiveLog(1)
                    //   // if (loanDetailed?.status === "booked") {
                    //   //   setPromptOpenRejectByLoanHeadModal(true);
                    //   // } 
                    // } else if (
                    //   user?.role.includes(RolesContants.LOAN_OFFICER)
                    // ) {
                    //   if (loanDetailed?.status === "rejected") {
                    //     toast.error("Already in rejected status");
                    //   } else {
                    //     //Reject to members modal
                    //     setPromptOpenRejectModal(true);
                    //   }
                    // }
                  }}
                >
                  reject
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="grid gap-6 mb-1 md:grid-cols-2 bg-white px-2 py-10">
          <div className="sideOne">
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Full Name</div>
              <div className="value">{loanDetailed?.user?.name}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Staff ID</div>
              <div className="value">{loanDetailed?.user?.staff_id}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Facility</div>
              <div className="value">{loanDetailed?.user?.facility}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Email Address</div>
              <div className="value">{loanDetailed?.email}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Phone Number</div>
              <div className="value">{loanDetailed?.personal_phone}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Date Of Birth</div>
              <div className="value">{loanDetailed?.dateOfBirth}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Age</div>
              <div className="value">{loanDetailed?.age}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Gender</div>
              <div className="value">{loanDetailed?.gender}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Residential Address 1</div>
              <div className="value">
                <p>{loanDetailed?.residential_address1}</p>
                <p>{loanDetailed?.residential_address2}</p>
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Work Address</div>
              <div className="value">
                <p>{loanDetailed?.work_address1}</p>
                <p>{loanDetailed?.work_address2}</p>
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Work Phone</div>
              <div className="value">{loanDetailed?.work_phone}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">ID Card Type</div>
              <div className="value">{loanDetailed?.IdCardType}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">ID Number</div>
              <div className="value">{loanDetailed?.IDCardNumber}</div>
            </div>

            <hr />
            <h3>Guarantor 1</h3>

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Full name</div>
              <div className="value">
                {loanDetailed?.guarantor_name}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Guarantor Phone</div>
              <div className="value">{loanDetailed?.guarantor_phone}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Guarantor Staff ID</div>
              <div className="value">{loanDetailed?.guarantor_staff_id}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Ghana Card Number</div>
              <div className="value">
                {loanDetailed?.guarantor_ghana_card}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Ghana GPS Address</div>
              <div className="value">
                {loanDetailed?.guarantor_gps}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Occupation</div>
              <div className="value">
                {loanDetailed?.guarantor_occupation}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Mandate Number</div>
              <div className="value">
                {loanDetailed?.guarantor_mandate_number}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">OTP Number</div>
              <div className="value">
                {loanDetailed?.guarantor_otp_number}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Relationship</div>
              <div className="value">
                {loanDetailed?.guarantor_relationship}
              </div>
            </div>

          </div>

          <div className="sideTwo">
            <h3>Guarantor 2</h3>

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Full name</div>
              <div className="value">
                {loanDetailed?.guarantor_name2}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Guarantor Phone</div>
              <div className="value">{loanDetailed?.guarantor_phone2}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Guarantor Staff ID</div>
              <div className="value">{loanDetailed?.guarantor_staff_id2}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Ghana Card Number</div>
              <div className="value">
                {loanDetailed?.guarantor_ghana_card2}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Ghana GPS Addresss</div>
              <div className="value">
                {loanDetailed?.guarantor_gps2}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Occupation</div>
              <div className="value">
                {loanDetailed?.guarantor_occupation2}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Mandate Number</div>
              <div className="value">
                {loanDetailed?.guarantor_mandate_number2}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">OTP Number</div>
              <div className="value">
                {loanDetailed?.guarantor_otp_number2}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Relationship</div>
              <div className="value">
                {loanDetailed?.guarantor_relationship2}
              </div>
            </div>
            <hr />
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Loan Amount</div>
              <div className="value">{loanDetailed?.loanAmount}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Loan Term</div>
              <div className="value">{loanDetailed?.loanTerm}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Monthly Deduction</div>
              <div className="value">{loanDetailed?.monthlyDeduction}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Initail Amount</div>
              <div className="value">{loanDetailed?.initialAmount}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Total Collectable</div>
              <div className="value">{loanDetailed?.loantotalCollectable}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Affordability</div>
              <div className="value">{loanDetailed?.affordability}</div>
            </div>

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Mandate Number</div>
              <div className="value">{loanDetailed?.mandateNumber}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">OTP Number</div>
              <div className="value">{loanDetailed?.otpNumber}</div>
            </div>
          
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Loan Purpose</div>
              <div className="value">{loanDetailed?.purposeForLoan}</div>
            </div>

            <hr />
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">SNNIT Number</div>
              <div className="value">{loanDetailed?.ssnit}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Bank Name</div>
              <div className="value">{loanDetailed?.bank_name}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Account Holder Name</div>
              <div className="value">
                {loanDetailed?.bank_account_holder_name}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Bank Branch</div>
              <div className="value">{loanDetailed?.bank_branch}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Account Number</div>
              <div className="value">{loanDetailed?.bank_account_number}</div>
            </div>
            <hr />

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Employer Name</div>
              <div className="value">{loanDetailed?.employer_name}</div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Employee Start Date</div>
              <div className="value">{loanDetailed?.employment_start_date}</div>
            </div>

            <h1>Ghana Identification Card</h1>
              <div className="id_card_box">
                  {/* {console.log('order-new', order)} */}
                  <div className="imgCover">
                   <div className="imgBox">
                   <img src={loanDetailed?.frontIDImage+'?'+timestamp} alt="front-image"/>
                   </div>
                    {/* <a href={order?.frontIDImage} download={true} target="_blank"><MdOutlineSimCardDownload/></a> */}
                    <MdOutlineSimCardDownload className="download_btn cursor-pointer" onClick={() => {
                      FileSaver.saveAs(loanDetailed?.frontIDImage+'?'+timestamp, 'image.jpg')
                    }}/>
                  </div>
                  <div className="imgCover">
                   <div className="imgBox">
                   <img src={loanDetailed?.passportImage+'?'+timestamp} alt="back-image"/>
                   </div>
                    <MdOutlineSimCardDownload className="cursor-pointer download_btn" onClick={() => {
                      FileSaver.saveAs(loanDetailed?.passportImage+'?'+timestamp, 'image.jpg')
                    }}/>
                  </div>
              </div>


            {/* <div
              className="item grid  mb-3 md:grid-cols-2"
              style={{
                alignItems: "center",
              }}
            >
              <div className="id_card_box">
               <div className="imgCover">
                <div className="imgBox">
                <img src={loanDetailed?.frontIDImage} alt="id_front" />
                </div>
               </div>
                <div className="passportContainer">
                  <BiCloudDownload
                    className="idDownloadBtn"
                    onClick={() => {
                      handleImageDownload(
                        loanDetailed?.frontIDImage,
                        "image.jpg"
                      );
                    }}
                  />
                </div>

              </div>

              <div className="id_card_box">
                <img
                  src={loanDetailed?.passportImage}
                  alt="passport photo"
                  className="passportImage"
                />
                <div className="passportContainer">
                  <BiCloudDownload
                    className="passportDownloadBtn"
                    onClick={() => {
                      handleImageDownload(
                        loanDetailed?.passportImage,
                        "image.jpg"
                      );
                    }}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>

        {openPromptRejectModal && (
          <PromptModal
            closeModal={setPromptOpenRejectModal}
            width={"auto"}
            title="Reject to a member"
          >
            <div className="relative  ">
              <div className="p-6 text-center">
                {error && (
                  <div className=" mb-2">
                    <a className="text-red-400 border px-2 py-1 rounded-full">
                      {error}
                    </a>
                  </div>
                )}
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Rejected message
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    onChange={(e) => setRejectedMessage(e.target.value)}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write the reason for rejecting loan here. The member will see this..."
                  ></textarea>
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    checked={checkedFormEdit}
                    onChange={() => setCheckedFormEdit(!checkedFormEdit)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Allow member to <strong>Edit Form</strong>
                  </label>
                </div>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  onClick={() => {
                    if (rejectMessage === "") {
                      setError("Please add message first");

                      setTimeout(() => {
                        setError("");
                      }, 5000);
                    } else {
                      //mark as rejected
                      const payload = {
                        status: true,
                        message: rejectMessage,
                        resubmit: false,
                        editFormChecked: checkedFormEdit,
                        completed: false,
                      };

                      MarkedAsRejectedAppliedLoans(loanDetailed?._id, payload);
                      setRejectedMessage("");
                      setCheckedFormEdit(false);
                      setPromptOpenRejectModal(false);
                    }
                  }}
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  Reject
                </button>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => setPromptOpenRejectModal(false)}
                >
                  No, cancel
                </button>
              </div>
            </div>
          </PromptModal>
        )}

        {openPromptRejectByLoanHeadModal && (
          <PromptModal
            closeModal={setPromptOpenRejectByLoanHeadModal}
            width={"auto"}
            title="Reject loan to officers"
          >
            <div className="relative  ">
              <div className="p-6 text-center">
                {error && (
                  <div className=" mb-2">
                    <a className="text-red-400 border px-2 py-1 rounded-full">
                      {error}
                    </a>
                  </div>
                )}
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Rejected message
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    onChange={(e) => setRejectedMessage(e.target.value)}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write the reason for rejecting loan here. The loan officers will see this..."
                  ></textarea>
                </div>

                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  onClick={() => {
                    if (rejectMessage === "") {
                      setError("Please add message first");

                      setTimeout(() => {
                        setError("");
                      }, 5000);
                    } else {
                      //mark as rejected
                      const payload = {
                        status: true,
                        message: rejectMessage,
                        officerRejected: true,
                        rejectedBy: 'HEAD',
                        officer: user?.user?.name
                      };

                      MarkedAsRejectedAppliedLoansByLoanHeads(
                        loanDetailed?._id,
                        payload
                      );
                      setRejectedMessage("");

                      setPromptOpenRejectByLoanHeadModal(false);
                    }
                  }}
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  Reject
                </button>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => setPromptOpenRejectByLoanHeadModal(false)}
                >
                  No, cancel
                </button>
              </div>
            </div>
          </PromptModal>
        )}



{openEditLoanFormModal && (
          <PromptModal
            closeModal={setOpenEditLoanFormModal}
            width={"60%"}
            title="Edit Application"
            height={'70vh'}
          >
            <div className="relative  ">
              <div className="p-6 text-center">
            
              
              {/* Start of form */}

              <div className="grid gap-6 mb-1 md:grid-cols-2 bg-white px-2 py-10">
          <div className="sideOne">
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Full Name</div>
              <input
                    className="form-control"
                    type="text"
                    name="fullname"
                    placeholder=""
                    readOnly
                    defaultValue={loanDetailed?.user?.name}
                    id="fullname"
                  />
              {/* <div className="value">{loanDetailed?.user?.name}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Staff ID</div>
              <input
                    className="form-control"
                    type="text"
                    // name="fullname"
                    placeholder=""
                    readOnly
                    defaultValue={loanDetailed?.user?.staff_id}
                    id="fullname"
                  />
              {/* <div className="value">{loanDetailed?.user?.staff_id}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Facility</div>
              <input
                    className="form-control"
                    type="text"
                    readOnly
                    defaultValue={loanDetailed?.user?.facility}
                  
                  />
            
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Email Address</div>
              <input
                    className="form-control"
                    type="text"
                    readOnly
                    defaultValue={loanDetailed?.email}
                  />
              
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Phone Number</div>
              <input
                    className="form-control"
                    type="text"
                    readOnly
                    defaultValue={loanDetailed?.personal_phone}
                  />
              
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Date Of Birth</div>
              <input
                    className="form-control"
                    type="text"
                    readOnly
                    defaultValue={loanDetailed?.dateOfBirth}
                  />
             
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Age</div>
              <input
                    className="form-control"
                    type="text"
                    readOnly
                    defaultValue={loanDetailed?.age}
                  />
             
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Gender</div>
              <input
                    className="form-control"
                    type="text"
                    name="gender"
                    defaultValue={loanDetailed?.gender}
                    onChange={(e) => handleFormChange(e)}
                  />
           
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Residential Address 1</div>
              <div className="value">
              <input
                    className="form-control"
                    type="text"
                    name="residential_address1"
                    defaultValue={loanDetailed?.residential_address1}
                    onChange={(e) => handleFormChange(e)}
                  />
              <input
                    className="form-control"
                    type="text"
                    name="residential_address2"
                    defaultValue={loanDetailed?.residential_address2}
                    onChange={(e) => handleFormChange(e)}
                  />
              
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Work Address</div>
              <div className="value">
              <input
                    className="form-control"
                    type="text"
                    name="work_address1"
                    defaultValue={loanDetailed?.work_address1}
                    onChange={(e) => handleFormChange(e)}
                  />
                   <input
                    className="form-control"
                    type="text"
                    name="work_address2"
                    defaultValue={loanDetailed?.work_address2}
                    onChange={(e) => handleFormChange(e)}
                  />
               
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Work Phone</div>
              <div className="value">
              <input
                    className="form-control"
                    type="text"
                    name="work_phone"
                    defaultValue={loanDetailed?.work_phone}
                    onChange={(e) => handleFormChange(e)}
                  />
              
                </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">ID Card Type</div>
              <input
                    className="form-control"
                    type="text"
                    name="IdCardType"
                    defaultValue={loanDetailed?.IdCardType}
                    onChange={(e) => handleFormChange(e)}
                  />
              
              {/* <div className="value">{loanDetailed?.IdCardType}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">ID Number</div>
              <input
                    className="form-control"
                    type="text"
                    name="IDCardNumber"
                    defaultValue={loanDetailed?.IDCardNumber}
                    onChange={(e) => handleFormChange(e)}
                  />
            </div>

            <hr />
            <h3>Guarantor 1</h3>

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Full name</div>
              <div className="value">
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_name"
                    defaultValue={loanDetailed?.guarantor_name}
                    onChange={(e) => handleFormChange(e)}
                  />
                {/* {loanDetailed?.guarantor_name} */}
              </div>
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Guarantor Phone</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_phone"
                    defaultValue={loanDetailed?.guarantor_phone}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">{loanDetailed?.guarantor_phone}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Guarantor Staff ID</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_staff_id"
                    defaultValue={loanDetailed?.guarantor_staff_id}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">{loanDetailed?.guarantor_staff_id}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Ghana Card Number</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_staff_id"
                    defaultValue={loanDetailed?.guarantor_ghana_card}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_ghana_card}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Ghana GPS Address</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_gps"
                    defaultValue={loanDetailed?.guarantor_gps}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_gps}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Occupation</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_occupation"
                    defaultValue={loanDetailed?.guarantor_occupation}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_occupation}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Mandate Number</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_mandate_number"
                    defaultValue={loanDetailed?.guarantor_mandate_number}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_mandate_number}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">OTP Number</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_otp_number"
                    defaultValue={loanDetailed?.guarantor_otp_number}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_otp_number}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Relationship</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_relationship"
                    defaultValue={loanDetailed?.guarantor_relationship}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_relationship}
              </div> */}
            </div>

          </div>

          <div className="sideTwo">
            <h3>Guarantor 2</h3>

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Full name</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_name2"
                    defaultValue={loanDetailed?.guarantor_name2}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_name2}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Guarantor Phone</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_phone2"
                    defaultValue={loanDetailed?.guarantor_phone2}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">{loanDetailed?.guarantor_phone2}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Guarantor Staff ID</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_staff_id2"
                    defaultValue={loanDetailed?.guarantor_staff_id2}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">{loanDetailed?.guarantor_staff_id2}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Ghana Card Number</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_ghana_card2"
                    defaultValue={loanDetailed?.guarantor_ghana_card2}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_ghana_card2}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Ghana GPS Address</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_gps2"
                    defaultValue={loanDetailed?.guarantor_gps2}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_gps2}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Occupation</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_occupation2"
                    defaultValue={loanDetailed?.guarantor_occupation2}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_occupation2}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Mandate Number</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_mandate_number2"
                    defaultValue={loanDetailed?.guarantor_mandate_number2}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_mandate_number2}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">OTP Number</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_otp_number2"
                    defaultValue={loanDetailed?.guarantor_otp_number2}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_otp_number2}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Relationship</div>
              <input
                    className="form-control"
                    type="text"
                    name="guarantor_relationship2"
                    defaultValue={loanDetailed?.guarantor_relationship2}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">
                {loanDetailed?.guarantor_relationship2}
              </div> */}
            </div>
            <hr />
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Loan Amount</div>
              <input
                    className="form-control"
                    type="text"
                    name="loanAmount"
                    defaultValue={loanDetailed?.loanAmount}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">{loanDetailed?.loanAmount}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Loan Term</div>
              <input
                    className="form-control"
                    type="text"
                    name="loanTerm"
                    defaultValue={loanDetailed?.loanTerm}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">{loanDetailed?.loanTerm}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Affordability</div>
              <input
                    className="form-control"
                    type="text"
                    name="affordability"
                    defaultValue={loanDetailed?.affordability}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">{loanDetailed?.affordability}</div> */}
            </div>

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Mandate Number</div>
              <input
                    className="form-control"
                    type="text"
                    name="mandateNumber"
                    defaultValue={loanDetailed?.mandateNumber}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">{loanDetailed?.mandateNumber}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">OTP Number</div>
              <input
                    className="form-control"
                    type="text"
                    name="otpNumber"
                    defaultValue={loanDetailed?.otpNumber}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">{loanDetailed?.otpNumber}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Monthly Deduction</div>
              <input
                    className="form-control"
                    type="text"
                    name="monthlyDeduction"
                    defaultValue={loanDetailed?.monthlyDeduction}
                    onChange={(e) => handleFormChange(e)}
                  />

              {/* <div className="value">{loanDetailed?.monthlyDeduction}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Loan Purpose</div>
              <input
                    className="form-control"
                    type="text"
                    name="purposeForLoan"
                    defaultValue={loanDetailed?.purposeForLoan}
                    onChange={(e) => handleFormChange(e)}
                  />

              {/* <div className="value">{loanDetailed?.purposeForLoan}</div> */}
            </div>

            <hr />
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">SNNIT Number</div>
              <input
                    className="form-control"
                    type="text"
                    name="ssnit"
                    defaultValue={loanDetailed?.ssnit}
                    onChange={(e) => handleFormChange(e)}
                  />
              {/* <div className="value">{loanDetailed?.ssnit}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Bank Name</div>
              <input
                    className="form-control"
                    type="text"
                    name="bank_name"
                    defaultValue={loanDetailed?.bank_name}
                    onChange={(e) => handleFormChange(e)}
                    readOnly
                  />
              {/* <div className="value">{loanDetailed?.bank_name}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Account Holder Name</div>
              <input
                    className="form-control"
                    type="text"
                    name="bank_account_holder_name"
                    defaultValue={loanDetailed?.bank_account_holder_name}
                    onChange={(e) => handleFormChange(e)}
                    readOnly
                  />
              {/* <div className="value">
                {loanDetailed?.bank_account_holder_name}
              </div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Bank Branch</div>
              <input
                    className="form-control"
                    type="text"
                    name="bank_branch"
                    defaultValue={loanDetailed?.bank_branch}
                    onChange={(e) => handleFormChange(e)}
                    readOnly
                  />
              {/* <div className="value">{loanDetailed?.bank_branch}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Account Number</div>
              <input
                    className="form-control"
                    type="text"
                    name="bank_account_number"
                    defaultValue={loanDetailed?.bank_account_number}
                    onChange={(e) => handleFormChange(e)}
                    readOnly
                  />
              {/* <div className="value">{loanDetailed?.bank_account_number}</div> */}
            </div>
            <hr />

            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Employer Name</div>
              <input
                    className="form-control"
                    type="text"
                    name="employer_name"
                    defaultValue={loanDetailed?.employer_name}
                    onChange={(e) => handleFormChange(e)}
                    
                  />
              {/* <div className="value">{loanDetailed?.employer_name}</div> */}
            </div>
            <div className="item grid  mb-3 md:grid-cols-2">
              <div className="name">Employee Start Date</div>
              <input
                    className="form-control"
                    type="date"
                    name="employment_start_date"
                    defaultValue={loanDetailed?.employment_start_date}
                    onChange={(e) => handleFormChange(e)}
                    
                  />
              {/* <div className="value">{loanDetailed?.employment_start_date}</div> */}
            </div>

            {/* <div
              className="item grid  mb-3 md:grid-cols-2"
              style={{
                alignItems: "center",
              }}
            >
              <div>
                <img src={loanDetailed?.frontIDImage+'?'+timestamp} id="frontIDCard" alt="id_front" />
                <div className="passportContainer">
                  <BiCloudDownload
                    className="idDownloadBtn"
                    onClick={() => {
                      handleImageDownload(
                        loanDetailed?.frontIDImage,
                        "image.jpg"
                      );
                    }}
                  />
                </div>
              </div>
              <div>
                <img
                  src={loanDetailed?.passportImage+'?'+timestamp}
                  alt="passport photo"
                  className="passportImage"
                  id="backIDCard"
                />
                <div className="passportContainer">
                  <BiCloudDownload
                    className="passportDownloadBtn"
                    onClick={() => {
                      handleImageDownload(
                        loanDetailed?.passportImage,
                        "image.jpg"
                      );
                    }}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
              {/* End of form */}
               
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  onClick={() => {

                    // if (rejectMessage === "") {
                    //   setError("Please add message first");

                    //   setTimeout(() => {
                    //     setError("");
                    //   }, 5000);
                    // } else {
                    //   //mark as rejected
                    //   const payload = {
                    //     status: true,
                    //     message: rejectMessage,
                    //     resubmit: false,
                    //     editFormChecked: checkedFormEdit,
                    //     completed: false,
                    //   };

                    //   MarkedAsRejectedAppliedLoans(loanDetailed?._id, payload);
                    //   setRejectedMessage("");
                    //   setCheckedFormEdit(false);
                    //   setPromptOpenRejectModal(false);
                    // }
                  }}
                  className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  Save Changes
                </button>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={handleFormSaveEdited}
                >
                  No, cancel
                </button>
              </div>
            </div>
          </PromptModal>
        )}

{openMessageModal && (
        <Modal
          width={"40%"}
          title={"Send Message to member"}
          closeModal={setOpenMessageModal}
        >
          <div className="modalBody">
            <div className="message">
            <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Message to member
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    onChange={(e) => setMemberMessage(e.target.value)}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your message here"
                  ></textarea>
            </div>
          </div>
          <div className="modalButton">
            <div className="btnCover">
              {/* <button type="button" className="modalBtn" onClick={handleSubmit}>
            Change
          </button> */}

              <button
                data-modal-toggle=""
                type="button"
                onClick={() => handleSendMemberMessage()}
                className="cancelBtn text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2.5 text-center mr-2"
              >
                Send Message
              </button>
              <button
                data-modal-toggle=""
                type="button"
                onClick={() => setOpenMessageModal(false)}
                className="cancelBtn text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2.5 text-center mr-2"
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}

        <ToastContainer autoClose={3000} />
      </RenderLoanPage>
    </div>
  );
}

export default LoanDetailed;
