import { combineReducers } from "redux";
import authReducer from "./authReducer";
import dataReducer from "./dataReducer";
import messageReducer from "./messageReducer";
import notificationReducer from "./notificationReducer";
import productReducer from "./productReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    data: dataReducer,
    product: productReducer,
    message: messageReducer,
    notification: notificationReducer,
})

export default rootReducer