
import axiosInstance from "../Axios/axios";
import { notificationConstants } from "../Constants/notificationConstant";

export const GetNotificationsByLoanOfficers = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: notificationConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(`/notifications/loan_officer`);
      if (response) {
        dispatch({
          type: notificationConstants.GET_NOTIFICATIONS_BY_LOAN_OFFICER,
          payLoad: {
            notifications: response.data,
          },
        });
        // dispatch({
        //   type: notificationConstants.RESPONSE_STATE,
        //   response: {
        //     state: "SUCCESS",
        //     message: "Notification loaded successfully",
        //   },
        // });
        dispatch({
          type: notificationConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: notificationConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get notification",
        },
      });
      dispatch({
        type: notificationConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetNotificationsByMartOfficers = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: notificationConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.get(`/notifications/mart_officer`);
      if (response) {
        dispatch({
          type: notificationConstants.GET_NOTIFICATIONS_BY_MART_OFFICER,
          payLoad: {
            notifications: response.data,
          },
        });
        // dispatch({
        //   type: notificationConstants.RESPONSE_STATE,
        //   response: {
        //     state: "SUCCESS",
        //     message: "Notification loaded successfully",
        //   },
        // });
        dispatch({
          type: notificationConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: notificationConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to get notification",
        },
      });
      dispatch({
        type: notificationConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const DeleteNotification = (notification_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: notificationConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.delete(`/notifications/${notification_id}`);
      if (response) {
        dispatch({
          type: notificationConstants.DELETE_NOTIFICATIONS,
        });
        dispatch({
          type: notificationConstants.RESPONSE_STATE,
          response: {
            state: "SUCCESS",
            message: "Notification removed",
          },
        });
        dispatch({
          type: notificationConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: notificationConstants.RESPONSE_STATE,
        response: {
          state: "ERROR",
          message: e?.response?.data?.message ?? "Unable to remove",
        },
      });
      dispatch({
        type: notificationConstants.LOADING,
        isLoading: false,
      });
    }
  };
};



export const ResetResponse = () => {
  return async (dispatch) => {
    dispatch({
      type: notificationConstants.RESPONSE_STATE,
      response: {
        state: null,
        message: "",
      },
    });
  };
};