/* eslint-disable  */
import React, { useEffect, useState } from 'react'
import { GrProjects } from 'react-icons/gr'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { productActionCreators } from '../../services/Actions'
import '../css/hirepurchase.css'
import RenderHirePurchasePage from '../RenderHirePurchasePage'
import * as moment from 'moment'
import Loader from '../../Components/Loader'
import { ToastContainer, toast } from 'react-toastify'
import { debounce } from 'lodash'
import ReactPaginate from 'react-paginate'
function Orders() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { GetSubmittedOrders, ResetProductResponse } = bindActionCreators(productActionCreators, dispatch)

  const product = useSelector(state => state.product)
  const { orders, isLoading, paginate, response } = product
  const [limit, setLimit] = useState(15)
  console.log('orders', orders)


  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(0);


  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(orders?.slice(itemOffset, endOffset));
    setPageCount(Number(paginate?.total));
  }, [itemOffset, itemsPerPage, orders]);

  const handlePageClick = (event) => {
    console.log("event", event);
    GetSubmittedOrders(true, event.selected + 1, limit, null)

    console.log("currentPage", currentPage);

    const newOffset = (event.selected * itemsPerPage) % paginate?.total;

    setItemOffset(newOffset);
  };
  useEffect(() => {
    GetSubmittedOrders(true, 1, 15, null)
  }, [])

  useEffect(() => {
    GetSubmittedOrders(true, 1, limit, null)
  }, [limit])

  const onSearchTextChange = debounce((e) => {

    if (e?.target?.value) {
      GetSubmittedOrders(
        true,
        1,
        limit,
        `${e?.target?.value ? "&search=" + e?.target?.value : ""}`
      );
     
    } else {
      // setSearch(null);
      GetSubmittedOrders(true, 1, limit, null)
    }
  }, 800);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      setTimeout(() => {
        ResetProductResponse();

      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetProductResponse();

      }, 1500);
    }
  }, [response?.state]);
  return (
    <div className='Orders'>
      <RenderHirePurchasePage title={'Orders'}>
        {isLoading && <Loader />}
        <div className="row py-3">
          <div className="col-md-7">
            <label htmlFor="search">Search Query:</label>
            <input
              type={"search"}
              id="search"
              placeholder="search by staff id, name"
              className="form-control"
              onChange={onSearchTextChange}
            />
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-2">
            <label htmlFor="search">List to show:</label>
            <select
              className="form-control"
              onChange={(e) => setLimit(e.target.value)}
            >
              <option value={5}>5</option>
              <option value={15}>15</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={300}>300</option>
            </select>
          </div>
        </div>
        {orders?.length > 0 ? (<>
          <div className='orderContainer'>
            {orders?.map((order, idx) => {
              console.log('order', order)
              return (
                <div className='orderCard' key={idx}>
                  <div className='items'>
                    <div className='image'>
                      {/* <img src='/images/dev/del.png' alt='product'/> */}
                      <img src={order?.user_id?.profile_image} alt='product' />
                    </div>
                    <div className='product-name'>
                      {order?.user_id?.name}
                    </div>
                    <div className='price'><small className='text-gray-500'>Staff ID:</small> {order?.staff_id}</div>
                    <div className='ordered_date'>Ordered on: <span>{moment(order?.created_at).format("MMM Do YY")}</span></div>
                    <button onClick={() => history.push({ pathname: '/mart/order-detailed', state: order })}>
                      {/* <span><GrProjects size={'14px'} color=""/></span> */}
                      <span>Process</span>
                    </button>
                  </div>
                </div>
              )
            })}
          </div>

        </>) : (<>Not found</>)}

        {orders.length > 0 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="active"
          />
        )}

        <ToastContainer autoClose={3000} />
      </RenderHirePurchasePage>
    </div>
  )
}

export default Orders