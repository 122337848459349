/* eslint-disable  */
import React, { useEffect, useRef, useState } from 'react'
import { BiSupport } from 'react-icons/bi'
import { BsCalendar2DateFill } from 'react-icons/bs'
import { GrStatusInfo } from 'react-icons/gr'
import { MdOutlineUpdate } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { bindActionCreators } from 'redux'
import Loader from '../../Components/Loader'
import { authActionCreators, messageActionCreators } from '../../services/Actions'
import RenderHirePurchasePage from '../RenderHirePurchasePage'
import moment from 'moment';
import './HirePurchaseMessage.css'

function HirePurchaseMessageDetailed() {
    const history = useHistory()
    const dispatch = useDispatch()
    const chat_id = history.location?.state?.chat_id
    const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);
    const { getMessageDetailed, addMessageBySystemUser,  ResetMessagesResponse} = bindActionCreators(messageActionCreators, dispatch);
    const [formData, setFormData] = useState({})
    const scrollRef = useRef();
    const handleChange = (e) => {
      setFormData({ ...formData, [e?.target?.name]: e?.target.value });
    };
    const auth = useSelector((state) => state?.auth);
    const messages = useSelector((state) => state?.message);
  
    const {message, isLoading, messageDetailed, response} = messages
  
    console.log('messageDetailed', messageDetailed)
  
    const handleSubmit = () => {
      const payload = {
        ...formData,
        system_user: auth?.user?._id,
        // user_id: auth?.user?._id,
        // member_profile: auth?.user?.profile_image,
        reference: messageDetailed?.reference,
      };
      addMessageBySystemUser(payload);
    };
  
    useEffect(() => {
      GetUserProfile()
    },[])
    useEffect(() => {
      getMessageDetailed(chat_id);
    }, [chat_id]);
  
    useEffect(() => {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messageDetailed?.chat]);
  
    useEffect(() => {
      if (response.state === "SUCCESS") {
        getMessageDetailed(chat_id);
        // toast.success(response?.message ?? "Notifications loaded");
        setTimeout(() => {
          ResetMessagesResponse();
        }, 1500);
      } else if (response.state === "ERROR") {
        toast.error(response?.message ?? "unable to get notification");
        setTimeout(() => {
          ResetMessagesResponse();
        }, 1500);
      }
    }, [response.state, response?.message]);
  return (
    <div className='MessageDetailed'>
        <RenderHirePurchasePage>
        {isLoading && <Loader />}
        <div onClick={() => history.goBack()}>back</div>
        <div className="messageShowCase">
          <h2>{messageDetailed?.subject}</h2>
          <div className="itemsConainer">
            <div className="item">
              <div>
                <GrStatusInfo className="icon" />
              </div>
              <div>Member Staff ID</div>
              <div><strong>{messageDetailed?.user?.staff_id}</strong></div>
            </div>
            <div className="item">
              <div>
                <BiSupport className="icon" />
              </div>
              <div>Department</div>
              <div>{messageDetailed?.system_user}</div>
            </div>
            <div className="item">
              <div>
                <MdOutlineUpdate className="icon" />
              </div>
              <div>Last Updated</div>
              <div>
                {moment(messageDetailed?.updated_at).format("MMM Do YY")}
              </div>
            </div>
            <div className="item">
              <div>
                <BsCalendar2DateFill className="icon" />
              </div>
              <div>Submitted on</div>
              <div>
                {moment(messageDetailed?.created_at).format("MMM Do YY")}
              </div>
            </div>
          </div>
        </div>

        <div className="messageBox">
          <h2>Message Area</h2>
          <section className="chat">
            <div className="messages-chat">
              {messageDetailed?.chat?.map((ch, index) => {
                return ch?.user_type === 'system_user'  ? (
                  <div ref={scrollRef}>
                  <div className="message text-only ">
                    <div className="response">
                      <p className="text"> {ch?.message}</p>
                      <p className="response-time"> {moment(ch?.date).format("MMM Do YY")}</p>
                    </div>
                    <div
                      className="photo"
                      // style={{
                      //   backgroundImage:
                      //     "url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)",
                      // }}
                    >
                      {/* <img src={messageDetailed?.user?.profile_image}/> */}
                      {/* <div className="online"></div> */}
                    </div>
                  </div>
                </div>
                ) : (
                  <div ref={scrollRef}>
                  <div className="message">
                    <div
                      className="photo"
                      // style={{
                      //   backgroundImage:
                      //     "url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)",
                      // }}
                    
                    >
                      <img className='rounded-full' />
                      {/* <img className='rounded-full' src={ch?.member_profile} alt='r'/> */}
                     
                    </div>
                    <p className="text"> {ch?.message}</p>
                  </div>
                  <p className="time"> {moment(ch?.date).format("MMM Do YY")}</p>
                </div>
                 
                );
              })}

              {/* <div className="message text-only">
                <div className="response">
                  <p className="text"> When can we meet ?</p>
                </div>
                <div
                  className="photo"
                  style={{
                    backgroundImage:
                      "url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)",
                  }}
                >
                  <div className="online"></div>
                </div>
              </div> */}
              {/* <p className="response-time time"> 15h04</p>

              <div className="message">
                <div
                  className="photo"
                  style={{
                    backgroundImage:
                      "url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)",
                  }}
                >
                  <div className="online"></div>
                </div>
                <p className="text"> 9 pm at the bar if possible 😳</p>
              </div>
              <p className="time"> 15h09</p> */}
            </div>
            <div className="footer-chat">
              <input
                type="text"
                onChange={handleChange}
                name="message"
                className="write-message"
                placeholder="Type your message here"
              ></input>
              <span className="button" onClick={handleSubmit}>
                Send
              </span>
              {/* <i
                className="icon send fa fa-paper-plane-o clickable"
                aria-hidden="true"
                onChange={handleSubmit}
              ></i> */}
            </div>
          </section>
        </div>
        </RenderHirePurchasePage>
        <ToastContainer autoClose={3000}/>
    </div>
  )
}

export default HirePurchaseMessageDetailed