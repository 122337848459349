export const productConstants = {
  LOADING: "LOADING",
  GET_PRODUCT_CATEGORY: "GET_PRODUCT_CATEGORY",
  GET_PRODUCT: "GET_PRODUCT",
  GET_PRODUCT_DETAILED: "GET_PRODUCT_DETAILED",
  GET_ORDERS: "GET_ORDERS",
  REQUEST_ORDER: "REQUEST_ORDER",
  RESPONSE_STATE: "RESPONSE_STATE",
  RESPONSE: "RESPONSE",
  GET_TOTALS: "GET_TOTALS",
  GET_SUPPLIERS: "GET_SUPPLIERS",

};
